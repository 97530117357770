import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import aws from "aws-sdk/clients/s3";
import uuidv1 from "uuid/v1";
import { config } from "aws-sdk/lib/core";

import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import {
  PutCompanyUpdateAction,
  PostCreateCompanyAction,
  ResetErrorDataAction,
} from "../actions/CompanyActions";
import { GetStatusListAction } from "../../Status/actions/StatusActions";
import { GetSectorListAction } from "../../Sector/actions/SectorActions";
import { GetClassListAction } from "../../Class/actions/ClassActions";
import { GetFounderListAction } from "../../Founders/actions/FoundersActions";
import {
  renderTextField,
  renderSelectField,
  renderFileUpload,
} from "../../../../shared/reduxFields";
import { showErrorMessage, imageRegExp } from "../../../../shared/utility";
import RenderMultiSelect from "../../../../shared/renderMultiSelect";
import validate from "./CompanyEditModalValidate";

const womenLedData = [
  { key: true, value: "True" },
  { key: false, value: "False" },
];

class AddEditCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusData: [],
      sectorData: [],
      classData: [],
      loadingDoc: false,
      loadingDocSS: false,
      isEnableUpload: false,
      isEnableUploadSS: false,
      isEnableSave: false,
      isEnableSaveSS: false,
      selectedDoc: "",
      selected: [],
      foundersData: [],
      defaultFounder: [],
    };
  }

  componentDidMount = () => {
    this.props.GetFounderListAction();
    this.props.GetStatusListAction();
    this.props.GetSectorListAction();
    this.props.GetClassListAction();
    if (this.props.type === "edit") {
      let companyDetails = this.props.companyDetails;
      this.props.dispatch(
        change("AddEditCompanyModalForm", "name", companyDetails.name)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "uploadDoc_S3", companyDetails.logo)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "website", companyDetails.website)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "email", companyDetails.commonEmail)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "womenLed", companyDetails.womenLed)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "status", companyDetails.status)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "class", companyDetails.class)
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "shortDescription",
          companyDetails.shortDescription
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "longerDescription",
          companyDetails.longerDescription
        )
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "linkedln", companyDetails.linkedln)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "twitter", companyDetails.twitter)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "instagram", companyDetails.instagram)
      );
      this.props.dispatch(
        change("AddEditCompanyModalForm", "jobBoard", companyDetails.jobBoard)
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "countryCode",
          companyDetails.numberISD
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "number",
          companyDetails.whatsappNumber
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "calendly",
          companyDetails.calendlyLink
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "individualPitch",
          companyDetails.individualPitch
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "uploadDoc_SS_S3",
          companyDetails.summarySlide
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "investmentMemo",
          companyDetails.investmentMemo
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "lastFundRaiseHeadline",
          companyDetails.lastFundRaiseHeadline
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "lastFundraiseLink",
          companyDetails.lastFundraiseLink
        )
      );
      this.props.dispatch(
        change(
          "AddEditCompanyModalForm",
          "fundingStage",
          companyDetails.stageOfFunding
        )
      );
      this.setState({
        selected: companyDetails.sector,
        defaultFounder: companyDetails.founders,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.statusList !== undefined &&
      this.props.statusList !== null &&
      this.props.statusList !== prevProps.statusList
    ) {
      this.setState({
        statusData: this.props.statusList,
      });
    }
    if (
      this.props.sectorList !== undefined &&
      this.props.sectorList !== null &&
      this.props.sectorList !== prevProps.sectorList
    ) {
      this.setState({
        sectorData: this.props.sectorList,
      });
    }
    if (
      this.props.classList !== undefined &&
      this.props.classList !== null &&
      this.props.classList !== prevProps.classList
    ) {
      this.setState({
        classData: this.props.classList,
      });
    }
    if (
      this.props.founderList !== undefined &&
      this.props.founderList !== null &&
      this.props.founderList !== prevProps.founderList
    ) {
      this.setState({
        foundersData: this.props.founderList,
      });
    }
  };

  onFormSubmitHandler = (formData) => {
    const companyDetails = formData;
    const founderData = this.state.founderList;
    const defaultFounder = this.state.defaultFounder;
    let selectedFounderData = [];
    if (
      this.props.type === "edit" &&
      defaultFounder.length > 0 &&
      founderData !== undefined
    ) {
      founderData.map((obj) => {
        selectedFounderData.push(obj.id);
      });
    } else if (
      this.props.type === "edit" &&
      defaultFounder.length > 0 &&
      founderData === undefined
    ) {
      defaultFounder.map((obj) => {
        selectedFounderData.push(obj.id);
      });
    } else if (founderData.length > 0) {
      founderData.map((obj) => {
        selectedFounderData.push(obj.id);
      });
    }

    console.log("selectedFounderData", selectedFounderData);

    const postData = {
      name: companyDetails.name,
      logo: companyDetails.uploadDoc_S3,
      founders: selectedFounderData,
      stageOfFunding: companyDetails.fundingStage,
      website: companyDetails.website,
      class: companyDetails.class,
      sector: this.state.selected,
      status: companyDetails.status,
      womenLed: companyDetails.womenLed,
      linkedln: companyDetails.linkedln,
      twitter: companyDetails.twitter,
      instagram: companyDetails.instagram,
      jobBoard: companyDetails.jobBoard,
      commonEmail: companyDetails.email,
      numberISD: companyDetails.countryCode,
      whatsappNumber: companyDetails.number,
      calendlyLink: companyDetails.calendly,
      individualPitch: companyDetails.individualPitch,
      summarySlide: companyDetails.uploadDoc_SS_S3,
      investmentMemo: companyDetails.investmentMemo,
      lastFundRaiseHeadline: companyDetails.lastFundRaiseHeadline,
      lastFundraiseLink: companyDetails.lastFundraiseLink,
      longerDescription: companyDetails.longerDescription,
      shortDescription: companyDetails.shortDescription,
    };
    if (this.props.type === "add") {
      this.props.PostCreateCompanyAction(postData);
    } else {
      this.props.PutCompanyUpdateAction(postData, this.props.companyId);
    }
    this.props.handleClose();
  };

  onChangeHandler = (event, type) => {
    let file = event.target.files[0];
    if (file !== undefined) {
      if (!file) {
        this.props.ResetErrorDataAction();
      }
      if (imageRegExp.exec(file.name)) {
        this.setState(
          {
            selectedDoc: file,
            isEnableUpload: type === "Logo" ? true : this.state.isEnableUpload,
            isEnableUploadSS:
              type === "Summary" ? true : this.state.isEnableUploadSS,
          },
          () => {
            this.props.ResetErrorDataAction();
            if (type === "Logo") {
              this.props.dispatch(
                change("AddEditCompanyModalForm", "uploadDoc_S3", null)
              );
            } else {
              this.props.dispatch(
                change("AddEditCompanyModalForm", "uploadDoc_SS_S3", null)
              );
            }
          }
        );
      } else {
        showErrorMessage("File type not supported");
        this.setState(
          {
            selectedDoc: "",
            isEnableUpload: false,
            isEnableUploadSS: false,
          },
          () => {
            this.props.dispatch(
              change("AddEditCompanyModalForm", "uploadDoc_S3", null)
            );
          }
        );
      }
    }
  };

  onClickFileHandler = (type) => {
    this.setState(
      {
        loadingDoc: false,
        loadingDocSS: false,
        isEnableUpload: type === "Logo" ? false : this.state.isEnableUpload,
        isEnableUploadSS:
          type === "Summary" ? false : this.state.isEnableUploadSS,
        isEnableSave: type === "Logo" ? false : this.state.isEnableSave,
        isEnableSaveSS: type === "Summary" ? false : this.state.isEnableSaveSS,
        selectedDoc: "",
      },
      () => {
        if (type === "Logo") {
          this.props.dispatch(
            change("AddEditCompanyModalForm", "uploadDoc_S3", null)
          );
          return (document.getElementById("uploadDoc_S3").value = null);
        } else {
          this.props.dispatch(
            change("AddEditCompanyModalForm", "uploadDoc_SS_S3", null)
          );
          return (document.getElementById("uploadDoc_SS_S3").value = null);
        }
      }
    );
  };

  onUploadClickHandler = (type) => {
    config.update({
      region: process.env.REACT_APP_S3_REGION_CONTENT,
      accessKeyId: process.env.REACT_APP_S3_ACCESSKEY_CONTENT,
      secretAccessKey: process.env.REACT_APP_S3_ACCESSSECRET_CONTENT,
    });

    const s3bucket = new aws();
    if (
      type === "Logo" ? this.state.isEnableUpload : this.state.isEnableUploadSS
    ) {
      this.setState(
        {
          loadingDoc: type === "Logo" ? true : false,
          loadingDocSS: type === "Summary" ? true : false,
        },
        () => {
          const params = {
            Key: uuidv1(),
            ContentType: `Image/${
              imageRegExp.exec(this.state.selectedDoc.name)[1]
            }`,
            Body: this.state.selectedDoc,
            Bucket: process.env.REACT_APP_S3_BUCKET_CONTENT,
            ACL: "public-read",
          };
          s3bucket.upload(params, (err, data) => {
            if (data && !err) {
              this.setState(
                {
                  loadingDoc: false,
                  loadingDocSS: false,
                  isEnableUpload:
                    type === "Logo" ? false : this.state.isEnableUpload,
                  isEnableUploadSS:
                    type === "Summary" ? false : this.state.isEnableUploadSS,
                  isEnableSave:
                    type === "Logo" ? true : this.state.isEnableSave,
                  isEnableSaveSS:
                    type === "Summary" ? true : this.state.isEnableSaveSS,
                  selectedDoc: "",
                },
                () => {
                  if (type === "Logo") {
                    this.props.dispatch(
                      change(
                        "AddEditCompanyModalForm",
                        "uploadDoc_S3",
                        data.Location
                      )
                    );
                  } else {
                    this.props.dispatch(
                      change(
                        "AddEditCompanyModalForm",
                        "uploadDoc_SS_S3",
                        data.Location
                      )
                    );
                  }
                }
              );
            } else {
              this.setState({
                loadingDoc: false,
                loadingDocSS: false,
                isEnableUpload: false,
                isEnableUploadSS: false,
                isEnableSave: false,
                isEnableSaveSS: false,
                selectedDoc: "",
              });
            }
          });
        }
      );
    }
  };

  handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      this.setState({
        selected: selected.length === sectorData.length ? [] : sectorData,
      });
      return;
    }
    this.setState({ selected: value });
  };

  foundersChangeHandler = (e, value) => {
    this.setState({
      founderList: value,
    });
  };

  render() {
    const { classes, handleSubmit, type, companyDetails } = this.props;
    const {
      statusData,
      sectorData,
      classData,
      isEnableUpload,
      isEnableUploadSS,
      isEnableSave,
      isEnableSaveSS,
      loadingDoc,
      loadingDocSS,
      selected,
      foundersData,
      defaultFounder,
    } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Dialog
              onClose={() => this.props.handleClose()}
              aria-labelledby="customized-dialog-title"
              open={() => this.props.handleOpen()}
            >
              <form
                onSubmit={handleSubmit((val) => this.onFormSubmitHandler(val))}
              >
                <MuiDialogTitle
                  className={classes.modelTitle}
                  id="customized-dialog-title"
                  onClose={() => this.props.handleClose()}
                >
                  <h6>{type === "add" ? "Add" : "Edit"} Company Details</h6>
                </MuiDialogTitle>
                <Divider />
                <MuiDialogContent>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        id="name"
                        name="name"
                        label="Enter Company Name"
                        required={true}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="website"
                        name="website"
                        label="Enter Website URL"
                        required={true}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Autocomplete
                        multiple
                        id="multiple-founder-select"
                        options={foundersData}
                        getOptionLabel={(option) =>
                          option.firstName + "" + option.lastName
                        }
                        defaultValue={defaultFounder}
                        filterSelectedOptions={true}
                        onChange={(e, value) =>
                          this.foundersChangeHandler(e, value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Select Founders"
                            placeholder="Select Founders"
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem md={9} xs={12}>
                      <Field
                        id="uploadLogo"
                        name="uploadLogo"
                        label="Select Logo"
                        required={
                          type === "add"
                            ? true
                            : companyDetails.logo === null ||
                              companyDetails.logo === ""
                            ? true
                            : false
                        }
                        onClick={() => this.onClickFileHandler("Logo")}
                        onChange={(e) => this.onChangeHandler(e, "Logo")}
                        component={renderFileUpload}
                      />
                    </GridItem>
                    <GridItem md={3} xs={12}>
                      <Button
                        disabled={!isEnableUpload || loadingDoc}
                        color="rose"
                        onClick={() => this.onUploadClickHandler("Logo")}
                      >
                        {!isEnableSave ? "Upload" : "Uploaded"}
                        {loadingDoc && <CircularProgress size={25} />}
                      </Button>
                    </GridItem>
                    <GridItem md={12} xs={12} hidden>
                      <Field
                        id="uploadDoc_S3"
                        name="uploadDoc_S3"
                        disabled="true"
                        required="true"
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem md={9} xs={12}>
                      <Field
                        id="uploadSummarySlide"
                        name="uploadSummarySlide"
                        label="Select Summary Slide"
                        onClick={() => this.onClickFileHandler("Summary")}
                        onChange={(e) => this.onChangeHandler(e, "Summary")}
                        component={renderFileUpload}
                      />
                    </GridItem>
                    <GridItem md={3} xs={12}>
                      <Button
                        disabled={!isEnableUploadSS || loadingDocSS}
                        color="rose"
                        onClick={() => this.onUploadClickHandler("Summary")}
                      >
                        {!isEnableSaveSS ? "Upload" : "Uploaded"}
                        {loadingDocSS && <CircularProgress size={25} />}
                      </Button>
                    </GridItem>
                    <GridItem md={12} xs={12}>
                      <Field
                        id="uploadDoc_SS_S3"
                        name="uploadDoc_SS_S3"
                        label="Sumary Slide Link"
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="email"
                        name="email"
                        label="Enter Common Email"
                        required={true}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Field
                        id="womenLed"
                        name="womenLed"
                        label="Is Women Led?"
                        required={true}
                        component={renderSelectField}
                      >
                        {womenLedData.map((obj, index) => {
                          return (
                            <MenuItem key={index} value={obj.key}>
                              {obj.value}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Field
                        id="status"
                        name="status"
                        label="Select Status"
                        required={true}
                        component={renderSelectField}
                      >
                        {statusData.map((obj, index) => {
                          return (
                            <MenuItem key={index} value={obj.name}>
                              {obj.name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <RenderMultiSelect
                        label="Select Sector(s)"
                        selected={selected}
                        sectorData={sectorData}
                        handleChange={(e) => this.handleChange(e)}
                      />
                    </GridItem>
                    <GridItem xs={12} md={12}>
                      <Field
                        id="class"
                        name="class"
                        label="Select Class"
                        required={true}
                        component={renderSelectField}
                      >
                        {classData.map((obj, index) => {
                          return (
                            <MenuItem key={index} value={obj.id}>
                              {obj.name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="fundingStage"
                        name="fundingStage"
                        label="Stage of Funding"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="shortDescription"
                        name="shortDescription"
                        label="Enter Short Description"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="longerDescription"
                        name="longerDescription"
                        label="Enter Long Description"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="linkedln"
                        name="linkedln"
                        label="Enter Linkedin URL"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="twitter"
                        name="twitter"
                        label="Enter Twitter URL"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="instagram"
                        name="instagram"
                        label="Enter Instagram URL"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="jobBoard"
                        name="jobBoard"
                        label="Enter Job Board"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="countryCode"
                        name="countryCode"
                        label="Enter Country Code"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="number"
                        name="number"
                        label="Enter Whats App Number"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="calendly"
                        name="calendly"
                        label="Enter Calendly Link"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="individualPitch"
                        name="individualPitch"
                        label="Enter individual Pitch Link"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="investmentMemo"
                        name="investmentMemo"
                        label="Enter Investment Memo Link"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="lastFundRaiseHeadline"
                        name="lastFundRaiseHeadline"
                        label="Last Fundraise Headline"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        id="lastFundraiseLink"
                        name="lastFundraiseLink"
                        label="Last Fundraise Link"
                        required={false}
                        component={renderTextField}
                      />
                    </GridItem>
                  </GridContainer>
                </MuiDialogContent>
                <MuiDialogActions>
                  <Button simple type="submit" color="rose">
                    SUBMIT
                  </Button>
                  <Button link onClick={() => this.props.handleClose()}>
                    CLOSE
                  </Button>
                </MuiDialogActions>
              </form>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddEditCompanyModal = reduxForm({
  form: "AddEditCompanyModalForm",
  validate,
  enableReinitialize: true,
})(AddEditCompanyModal);

function mapStateToProps(state) {
  return {
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
    statusList:
      state.statusReducer.statusList !== null &&
      state.statusReducer.statusList.data,
    sectorList:
      state.sectorReducer.sectorList !== null &&
      state.sectorReducer.sectorList.data,
    classList:
      state.classReducer.classList !== null &&
      state.classReducer.classList.data,
    founderList:
      state.founderReducer.founderList !== null &&
      state.founderReducer.founderList.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        PutCompanyUpdateAction,
        ResetErrorDataAction,
        PostCreateCompanyAction,
        GetStatusListAction,
        GetSectorListAction,
        GetClassListAction,
        GetFounderListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddEditCompanyModal));
