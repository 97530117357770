import { InvestorTypeActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createInvestorType: null,
    investorTypeList: null,
    updateInvestorType: null,
    deleteInvestorType: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case InvestorTypeActionTypes.post_createInvestorType.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorTypeActionTypes.post_createInvestorType.SUCCESS:
            return {
                ...state,
                loading: false,
                createInvestorType: payload,
            };
        case InvestorTypeActionTypes.post_createInvestorType.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorTypeActionTypes.get_investorTypeList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorTypeActionTypes.get_investorTypeList.SUCCESS:
            return {
                ...state,
                loading: false,
                investorTypeList: payload,
            };
        case InvestorTypeActionTypes.get_investorTypeList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorTypeActionTypes.delete_investorType.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorTypeActionTypes.delete_investorType.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteInvestorType: payload,
            };
        case InvestorTypeActionTypes.delete_investorType.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorTypeActionTypes.put_updateInvestorTypeDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorTypeActionTypes.put_updateInvestorTypeDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                updateInvestorType: payload,
            };
        case InvestorTypeActionTypes.put_updateInvestorTypeDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorTypeActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
