import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import { GetInvestedCompaniesListAction, PostApproveInvestedCompaniesByInvestorAction } from './actions/InvestorsActions';
import DeleteInvestorModal from './Modal/DeleteInvestorsModal';


class AllInvestedCompaniesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            investorId: 0,
            investorDetails: '',
            openDeleteInvestorModal: false,
            type: ''
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.props.GetInvestedCompaniesListAction();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.investedCompaniesList !== undefined && this.props.investedCompaniesList !== null && this.props.investedCompaniesList !== prevProps.investedCompaniesList) {
            this.setState({
                data: this.props.investedCompaniesList
            })
        }
    }

    handleDeleteInvestor = (event, rowData) => {
        let investorId = rowData.id;
        this.setState({
            investorId: investorId,
            investorDetails: rowData,
            openDeleteInvestorModal: true
        })
    }

    handleClose = () => {
        this.setState({
            openDeleteInvestorModal: false,
            investorId: 0,
            investorDetails: ''
        })
    }

    handleApproveInvestor = (event, rowData) => {
        let investorId = rowData.investor.id;
        let companyId = rowData.company.id;
        let postData = {
            "investorId": investorId,
            "companyId": companyId
        }
        const approved = !rowData.approved
        const queryParam = `?approved=${approved}`
        this.props.PostApproveInvestedCompaniesByInvestorAction(postData, queryParam);
    }

    render() {
        const { loading } = this.props;
        const { 
            data, openDeleteInvestorModal, 
            investorId, investorDetails
        } = this.state;

        return (
            <>
                {openDeleteInvestorModal && <DeleteInvestorModal isApproved={true} isVerified={true} investorId={investorId} investorDetails={investorDetails} handleClose={() => this.handleClose()}/>}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>All Invested Companies List For An Investor</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable 
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        
                                        { 
                                            title: 'Email', 
                                            field: 'investor.email'                            
                                        },
                                        { 
                                            title: 'Companies', 
                                            field: 'company.name'                            
                                        },
                                        { 
                                            title: 'Approved', 
                                            field: 'approved', 
                                            render: rowData => rowData.approved === true 
                                            ? <span style={{ color: 'green' }}><b>Yes</b></span> 
                                            : <span style={{ color: 'red' }}><b>No</b></span>
                                        }
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        sorting: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                        exportButton: true,
                                        pageSize: 200,
                                        pageSizeOptions: [100,200,300]
                                    }}
                                    isLoading={loading}
                                    actions={
                                        [
                                            {
                                                icon: Refresh,
                                                tooltip: 'Refresh List',
                                                isFreeAction: true,
                                                onClick: () => this.loadData()
                                            },
                                            rowData => ({
                                                icon: rowData.approved === false ? CheckCircleOutlineIcon : CancelIcon,
                                                tooltip: rowData.approved === false ? 'Approve Investor Invested Companies' : 'Disapprove Investor Invested Companies',
                                                onClick: (event, rowData) => this.handleApproveInvestor(event, rowData)
                                            }),
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

AllInvestedCompaniesList = reduxForm({
    form: 'AllInvestedCompaniesListForm',
    enableReinitialize: true
})(AllInvestedCompaniesList)

function mapStateToProps(state) {
    return {
        loading: state.investorReducer.loading,
        investedCompaniesList: state.investorReducer.investedCompaniesList !== null && state.investorReducer.investedCompaniesList.data,
        loggedInUserDetails: state.investorReducer.loggedInUserDetails !== null && state.investorReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            GetInvestedCompaniesListAction,
            PostApproveInvestedCompaniesByInvestorAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AllInvestedCompaniesList))
