import { CompanyActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createCompany: null,
    companyList: null,
    UpdateCompany: null,
    deleteCompany: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CompanyActionTypes.post_createCompany.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CompanyActionTypes.post_createCompany.SUCCESS:
            return {
                ...state,
                loading: false,
                createCompany: payload,
            };
        case CompanyActionTypes.post_createCompany.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case CompanyActionTypes.get_companyList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CompanyActionTypes.get_companyList.SUCCESS:
            return {
                ...state,
                loading: false,
                companyList: payload,
            };
        case CompanyActionTypes.get_companyList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case CompanyActionTypes.delete_company.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CompanyActionTypes.delete_company.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteCompany: payload,
            };
        case CompanyActionTypes.delete_company.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case CompanyActionTypes.put_updateCompanyDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CompanyActionTypes.put_updateCompanyDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                UpdateCompany: payload,
            };
        case CompanyActionTypes.put_updateCompanyDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case CompanyActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
