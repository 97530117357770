import { ClassActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createClass: null,
    classList: null,
    UpdateClass: null,
    deleteClass: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ClassActionTypes.post_createClass.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ClassActionTypes.post_createClass.SUCCESS:
            return {
                ...state,
                loading: false,
                createClass: payload,
            };
        case ClassActionTypes.post_createClass.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ClassActionTypes.get_classList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ClassActionTypes.get_classList.SUCCESS:
            return {
                ...state,
                loading: false,
                classList: payload,
            };
        case ClassActionTypes.get_classList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ClassActionTypes.delete_class.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ClassActionTypes.delete_class.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteClass: payload,
            };
        case ClassActionTypes.delete_class.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ClassActionTypes.put_updateClassDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ClassActionTypes.put_updateClassDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                UpdateClass: payload,
            };
        case ClassActionTypes.put_updateClassDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ClassActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
