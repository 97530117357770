const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
        errors.name = 'First Name Required';
    }
    if (!values.designation) {
        errors.designation = 'Designation Required';
    }
    if (!values.uploadDoc_S3) {
        errors.uploadExcel = 'Image Required';
    }
    return errors;
};
export default validate;