/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_SECTOR_API = `${API_URL}/sectors/add`;
export const GET_SECTOR_LIST_API = `${API_URL}/sectors/get`;
export const PUT_UPDATE_SECTOR_API = `${API_URL}/sectors/update`;
export const DELETE_SECTOR_API = `${API_URL}/sectors/delete`;

export const SectorActionTypes = {
    post_createSector: createRequestActionTypes('POST_CREATE_SECTOR'),
    get_sectorList: createRequestActionTypes('GET_SECTOR_LIST'),
    put_updateSectorDetail: createRequestActionTypes('UPDATE_SECTOR_DETAIL'),
    delete_sector: createRequestActionTypes('DELETE_SECTOR'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};