import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { reduxForm } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { PostBlockUnblockUserByIdAction } from '../actions/UamActions';

class DeleteUserModal extends Component {

    onFormSubmitHandler = () => {
        this.props.PostBlockUnblockUserByIdAction(this.props.uamId);
        this.props.handleClose();
    }

    render() {
        const { classes, handleSubmit, status } = this.props;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>Delete User</h6>
                                </MuiDialogTitle>
                                <Divider />
                                <MuiDialogContent>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <span>Are you sure about Delete this user?</span>
                                        </GridItem>
                                    </GridContainer>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple type="submit" color="rose">
                                        YES! DELETE USER
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

DeleteUserModal = reduxForm({
    form: "DeleteUserModalForm",
    enableReinitialize: true
})(DeleteUserModal);


function mapStateToProps(state) {
    return {
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostBlockUnblockUserByIdAction
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DeleteUserModal));
