/**
 @desc
 * Centralized unique action types for Company module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_COMPANY_API = `${API_URL}/company/add`;
export const GET_COMPANY_LIST_API = `${API_URL}/company/get`;
export const PUT_UPDATE_COMPANY_API = `${API_URL}/company/update`;
export const DELETE_COMPANY_API = `${API_URL}/company/delete`;

export const CompanyActionTypes = {
    post_createCompany: createRequestActionTypes('POST_CREATE_COMPANY'),
    get_companyList: createRequestActionTypes('GET_COMPANY_LIST'),
    put_updateCompanyDetail: createRequestActionTypes('UPDATE_COMPANY_DETAIL'),
    delete_company: createRequestActionTypes('DELETE_COMPANY'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};