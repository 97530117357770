import { PitchDayActionTypes } from "../constants/index";

const initialState = {
  loading: false,
  magicToken: null,
  magicTokenScaleUp: null,
  allPitchEvents: null,
  createPitchEvents: null,
  updatePitchEvents: null,
  companiesPitchEvents: null,
  investorPitchEvents: null,
  updateCompaniesToPitchEvents: null,
  createPitchEventsVideo: null,
  createPitchEventsLocation: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PitchDayActionTypes.post_generateMagicToken.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.post_generateMagicToken.SUCCESS:
      return {
        ...state,
        loading: false,
        magicToken: payload,
      };
    case PitchDayActionTypes.post_generateMagicToken.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.post_magicTokenScaleUp.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.post_magicTokenScaleUp.SUCCESS:
      return {
        ...state,
        loading: false,
        magicTokenScaleUp: payload,
      };
    case PitchDayActionTypes.post_magicTokenScaleUp.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.post_createPitchEventsVideo.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.post_createPitchEventsVideo.SUCCESS:
      return {
        ...state,
        loading: false,
        createPitchEventsVideo: payload,
      };
    case PitchDayActionTypes.post_createPitchEventsVideo.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.post_createPitchEventsLocation.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.post_createPitchEventsLocation.SUCCESS:
      return {
        ...state,
        loading: false,
        createPitchEventsLocation: payload,
      };
    case PitchDayActionTypes.post_createPitchEventsLocation.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.put_companiesToPitchEvents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.put_companiesToPitchEvents.SUCCESS:
      return {
        ...state,
        loading: false,
        updateCompaniesToPitchEvents: payload,
      };
    case PitchDayActionTypes.put_companiesToPitchEvents.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.get_allPitchEvents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.get_allPitchEvents.SUCCESS:
      return {
        ...state,
        loading: false,
        allPitchEvents: payload,
      };
    case PitchDayActionTypes.get_allPitchEvents.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.post_createPitchEvents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.post_createPitchEvents.SUCCESS:
      return {
        ...state,
        loading: false,
        createPitchEvents: payload,
      };
    case PitchDayActionTypes.post_createPitchEvents.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.patch_updatePitchEvents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.patch_updatePitchEvents.SUCCESS:
      return {
        ...state,
        loading: false,
        createPitchEvents: payload,
      };
    case PitchDayActionTypes.patch_updatePitchEvents.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.get_companiesPitchEvents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.get_companiesPitchEvents.SUCCESS:
      return {
        ...state,
        loading: false,
        companiesPitchEvents: payload,
      };
    case PitchDayActionTypes.get_companiesPitchEvents.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case PitchDayActionTypes.get_investorPitchEvents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PitchDayActionTypes.get_investorPitchEvents.SUCCESS:
      return {
        ...state,
        loading: false,
        investorPitchEvents: payload,
      };
    case PitchDayActionTypes.get_investorPitchEvents.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
