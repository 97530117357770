
/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    FounderActionTypes,
    POST_CREATE_FOUNDER_API,
    GET_FOUNDER_LIST_API,
    PUT_UPDATE_FOUNDER_API,
    DELETE_FOUNDER_API,
    POST_ADD_FOUNDER_TO_COMPANY_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateFounderAction = (postData, type) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(FounderActionTypes.post_createFounder.REQUEST));
        fetch(`${POST_CREATE_FOUNDER_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(FounderActionTypes.post_createFounder.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(FounderActionTypes.post_createFounder.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(FounderActionTypes.post_createFounder.SUCCESS, response));
                    dispatch(reset('AddEditFounderModalForm'));
                    showSuccessMessage('Founder Created Successfully');
                    dispatch(GetFounderListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(FounderActionTypes.post_createFounder.FAILURE));
                console.log('error post_createFounder ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(FounderActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetFounderListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(FounderActionTypes.get_founderList.REQUEST));
        fetch(`${GET_FOUNDER_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(FounderActionTypes.get_founderList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(FounderActionTypes.get_founderList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(FounderActionTypes.get_founderList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(FounderActionTypes.get_founderList.FAILURE));
                console.log('error get_founderList ..', error);
            });
    };
};

export const PutFounderUpdateAction = (postData, founderId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(FounderActionTypes.put_updateFounderDetail.REQUEST));
        fetch(`${PUT_UPDATE_FOUNDER_API}/${founderId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(FounderActionTypes.put_updateFounderDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(FounderActionTypes.put_updateFounderDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(FounderActionTypes.put_updateFounderDetail.SUCCESS, response));
                    showSuccessMessage('Founder Details Updated Successfully');
                    dispatch(GetFounderListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(FounderActionTypes.put_updateFounderDetail.FAILURE));
                console.log('error put_updateFounderDetail ..', error);
            });
    };
};

export const DeleteFounderAction = (founderId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(FounderActionTypes.delete_founder.REQUEST));
        fetch(`${DELETE_FOUNDER_API}/${founderId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(FounderActionTypes.delete_founder.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(FounderActionTypes.delete_founder.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(FounderActionTypes.delete_founder.SUCCESS, response));
                    showSuccessMessage('Founder Deleted Successfully');
                    dispatch(GetFounderListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(FounderActionTypes.delete_founder.FAILURE));
                console.log('error delete_founder ..', error);
            });
    };
};

export const PostAddFounderToCompanyAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(FounderActionTypes.post_addFounderToCompany.REQUEST));
        fetch(`${POST_ADD_FOUNDER_TO_COMPANY_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(FounderActionTypes.post_addFounderToCompany.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(FounderActionTypes.post_addFounderToCompany.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(FounderActionTypes.post_addFounderToCompany.SUCCESS, response));
                    showSuccessMessage('Founder Added To Company Successfully')
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(FounderActionTypes.post_addFounderToCompany.FAILURE));
                console.log('error post_addFounderToCompany ..', error);
            });
    };
};

