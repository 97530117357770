/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_FOUNDER_API = `${API_URL}/founders/add`;
export const GET_FOUNDER_LIST_API = `${API_URL}/founders/get`;
export const PUT_UPDATE_FOUNDER_API = `${API_URL}/founders/update`;
export const DELETE_FOUNDER_API = `${API_URL}/founders/delete`;
export const POST_ADD_FOUNDER_TO_COMPANY_API = `${API_URL}/founders/addFounderToCompany`;

export const FounderActionTypes = {
    post_createFounder: createRequestActionTypes('POST_CREATE_FOUNDER'),
    get_founderList: createRequestActionTypes('GET_FOUNDER_LIST'),
    put_updateFounderDetail: createRequestActionTypes('UPDATE_FOUNDER_DETAIL'),
    delete_founder: createRequestActionTypes('DELETE_FOUNDER'),
    post_addFounderToCompany: createRequestActionTypes('POST_ADD_FOUNDER_TO_COMPANY'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};