import React, { Component } from "react";
import moment from "moment";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh, Delete } from "@material-ui/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import {
  GetInvestorListAction,
  PutInvestorUpdateAction,
} from "../actions/InvestorsActions";
import DeleteInvestorModal from "../Modal/DeleteInvestorsModal";

class InvestorApproveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      investorId: 0,
      openImageViewer: false,
      imgsArray: [],
      investorDetails: "",
      openDeleteInvestorModal: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetInvestorListAction(false, false);
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.investorList !== undefined &&
      this.props.investorList !== null &&
      this.props.investorList !== prevProps.investorList
    ) {
      this.setState({
        data: this.props.investorList,
      });
    }
  };

  closeImageViewer = () => {
    this.setState({
      openImageViewer: false,
      imgsArray: [],
    });
  };

  handleApproveInvestor = (event, rowData) => {
    let investorId = rowData.id;
    let postData = {
      approved: true,
    };
    this.props.PutInvestorUpdateAction(postData, investorId, false, false);
    this.setState({
      investorId: investorId,
      investorDetails: rowData,
    });
  };

  handleDeleteInvestor = (event, rowData) => {
    let investorId = rowData.id;
    this.setState({
      investorId: investorId,
      investorDetails: rowData,
      openDeleteInvestorModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      openDeleteInvestorModal: false,
      investorId: 0,
      investorDetails: "",
    });
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      openDeleteInvestorModal,
      investorId,
      investorDetails,
    } = this.state;

    return (
      <>
        {openDeleteInvestorModal && (
          <DeleteInvestorModal
            isVerified={false}
            isApproved={false}
            investorId={investorId}
            investorDetails={investorDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Investors List For Approval</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "First Name",
                      field: "firstName",
                    },
                    {
                      title: "Last Name",
                      field: "lastName",
                    },
                    {
                      title: "Created At",
                      field: "createdAt",
                      render: (rowData) =>
                        rowData.createdAt !== null
                          ? moment(rowData.createdAt).format(
                              "DD/MM/YYYY hh:mm a"
                            )
                          : "NA",
                    },
                    {
                      title: "Email",
                      field: "email",
                    },
                    {
                      title: "Attend Day",
                      field: "attendDay",
                      render: (rowData) =>
                        rowData.attendDay !== null
                          ? moment(rowData.attendDay).format("DD/MM/YYYY")
                          : "NA",
                    },
                    {
                      title: "Attend Time",
                      field: "attendTime",
                      render: (rowData) =>
                        rowData.attendTime !== null
                          ? moment(rowData.attendTime).format("hh:mm A")
                          : "NA",
                    },
                    {
                      title: "About",
                      field: "about",
                    },
                    {
                      title: "LinkedIn",
                      field: "linkedinProfile",
                      render: (rowData) => (
                        <span>
                          <a
                            href={rowData.linkedinProfile}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>{" "}
                        </span>
                      ),
                    },
                    {
                      title: "Phone Number",
                      field: "phoneNumber",
                    },
                    {
                      title: "Type",
                      field: "typeOfInvestor",
                    },
                  ]}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  isLoading={loading}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    (rowData) => ({
                      icon: CheckCircleOutlineIcon,
                      tooltip: "Approve Investor",
                      onClick: (event, rowData) =>
                        this.handleApproveInvestor(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Investor",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteInvestor(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

InvestorApproveList = reduxForm({
  form: "InvestorApproveListForm",
  enableReinitialize: true,
})(InvestorApproveList);

function mapStateToProps(state) {
  return {
    loading: state.investorReducer.loading,
    investorList:
      state.investorReducer.investorList !== null &&
      state.investorReducer.investorList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetInvestorListAction,
        PutInvestorUpdateAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InvestorApproveList));
