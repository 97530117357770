/**
 @desc
 * Centralized unique action types for Pitch Day module.
 */

import {
  actionCreator,
  API_URL,
  checkHttpStatus,
  createRequestActionTypes,
  jsonApiHeader,
  showSuccessMessage,
  showErrorMessage,
} from "../../../../shared/utility";

export {
  jsonApiHeader,
  actionCreator,
  checkHttpStatus,
  showSuccessMessage,
  showErrorMessage,
};

export const POST_GENERATE_MAGIC_TOKEN_API = `${API_URL}/investors/generateMagicLink`;
export const POST_GENERATE_MAGIC_TOKEN_SCALE_UP_API = `${API_URL}/investors/scaleup/generateMagicLink`;
export const GET_ALL_PITCH_EVENTS_API = `${API_URL}/admins/getPitchDates`;
export const POST_CREATE_PITCH_EVENTS_API = `${API_URL}/admins/createPitchEvent`;
export const PATCH_UPDATE_PITCH_EVENTS_API = `${API_URL}/admins/updatePitchEvent`;
export const GET_COMPANIES_FOR_PITCH_EVENTS_API = `${API_URL}/admins/getCompaniesByEvent`;
export const GET_INVESTOR_FOR_PITCH_EVENTS_API = `${API_URL}/admins/getPitchEventInvestors`;
export const UPDATE_COMPANIES_FOR_PITCH_EVENTS_API = `${API_URL}/admins/updateCompaniesByEvent`;
export const POST_CREATE_PITCH_EVENTS_VIDEO_API = `${API_URL}/admins/createPitchEventVideo`;
export const POST_CREATE_PITCH_EVENTS_LOCATION_API = `${API_URL}/admins/createPitchEventLocation`;

export const PitchDayActionTypes = {
  post_generateMagicToken: createRequestActionTypes(
    "POST_GENERATE_MAGIC_TOKEN"
  ),
  post_magicTokenScaleUp: createRequestActionTypes(
    "POST_GENERATE_MAGIC_TOKEN_SCALE_UP"
  ),
  get_allPitchEvents: createRequestActionTypes("GET_ALL_PITCH_EVENTS"),
  post_createPitchEvents: createRequestActionTypes("POST_CREATE_PITCH_EVENTS"),
  patch_updatePitchEvents: createRequestActionTypes(
    "PATCH_UPDATE_PITCH_EVENTS"
  ),
  get_companiesPitchEvents: createRequestActionTypes(
    "GET_COMPANIES_FOR_PITCH_EVENTS"
  ),
  get_investorPitchEvents: createRequestActionTypes(
    "GET_INVESTOR_FOR_PITCH_EVENTS"
  ),
  put_companiesToPitchEvents: createRequestActionTypes(
    "PUT_COMPANIES_FOR_PITCH_EVENTS"
  ),
  post_createPitchEventsVideo: createRequestActionTypes(
    "POST_CREATE_PITCH_EVENTS_VIDEO"
  ),
  post_createPitchEventsLocation: createRequestActionTypes(
    "POST_CREATE_PITCH_EVENTS_LOCATION"
  ),
};
