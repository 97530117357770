
/**
 @desc
 * Centralized unique actions for Uam Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    UamActionTypes,

    GET_ALL_USER_API,
    POST_CREATE_USER_API,
    POST_UPDATE_USER_API,
    POST_BLOCK_UNBLOCK_USER_BY_ID_API,

    GET_ALL_ROLES_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';


export const GetAllUamAction = () => {
    return (dispatch, getState) => {
       let token = getState().loginReducer.loggedInUserDetails.data.token;

        dispatch(actionCreator(UamActionTypes.get_AllUsers.REQUEST));
        fetch(`${GET_ALL_USER_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(UamActionTypes.get_AllUsers.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(UamActionTypes.get_AllUsers.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(UamActionTypes.get_AllUsers.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(UamActionTypes.get_AllUsers.FAILURE));
                console.log('error get_AllUsers ..', error);
            });
    };
};


export const PostBlockUnblockUserByIdAction = (userId) => {
    return (dispatch, getState) => {
       let token = getState().loginReducer.loggedInUserDetails.data.token;

        dispatch(actionCreator(UamActionTypes.post_BlockUnblockUser.REQUEST));
        fetch(`${POST_BLOCK_UNBLOCK_USER_BY_ID_API}/${userId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(UamActionTypes.post_BlockUnblockUser.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(UamActionTypes.post_BlockUnblockUser.FAILURE, response));
                    window.location.reload();
                } else {
                    showSuccessMessage('User Deleted Successfully');
                    dispatch(actionCreator(UamActionTypes.post_BlockUnblockUser.SUCCESS, response));
                    dispatch(GetAllUamAction());
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(UamActionTypes.post_BlockUnblockUser.FAILURE));
                console.log('error post_BlockUnblockUser ..', error);
            });
    };
};

export const PostAddUserAction = (postData) => {
    return (dispatch, getState) => {
       let token = getState().loginReducer.loggedInUserDetails.data.token;

        dispatch(actionCreator(UamActionTypes.post_CreateUser.REQUEST));
        fetch(`${POST_CREATE_USER_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(UamActionTypes.post_CreateUser.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(UamActionTypes.post_CreateUser.FAILURE, response));
                    window.location.reload();
                } else {
                    showSuccessMessage(`User Added Successfully`);
                    dispatch(actionCreator(UamActionTypes.post_CreateUser.SUCCESS, response));
                    dispatch(GetAllUamAction());
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(UamActionTypes.post_CreateUser.FAILURE));
                console.log('error post_CreateUser ..', error);
            });
    };
};

export const PostUpdateUserAction = (postData, userId) => {
    return (dispatch, getState) => {
       let token = getState().loginReducer.loggedInUserDetails.data.token;

        dispatch(actionCreator(UamActionTypes.post_UpdateUser.REQUEST));
        fetch(`${POST_UPDATE_USER_API}/${userId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(UamActionTypes.post_UpdateUser.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(UamActionTypes.post_UpdateUser.FAILURE, response));
                    window.location.reload();
                } else {
                    showSuccessMessage(`User Updated Successfully`);
                    dispatch(actionCreator(UamActionTypes.post_UpdateUser.SUCCESS, response));
                    dispatch(GetAllUamAction());
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(UamActionTypes.post_UpdateUser.FAILURE));
                console.log('error post_UpdateUser ..', error);
            });
    };
};

export const GetAllRolesAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(UamActionTypes.get_AllRoles.REQUEST));
        fetch(`${GET_ALL_ROLES_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(UamActionTypes.get_AllRoles.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(UamActionTypes.get_AllRoles.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(UamActionTypes.get_AllRoles.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(UamActionTypes.get_AllRoles.FAILURE));
                console.log('error get_AllRoles ..', error);
            });
    };
};