import { FounderActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createFounder: null,
    founderList: null,
    updateFounder: null,
    deleteFounder: null,
    addFounderToCompany: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FounderActionTypes.post_createFounder.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FounderActionTypes.post_createFounder.SUCCESS:
            return {
                ...state,
                loading: false,
                createFounder: payload,
            };
        case FounderActionTypes.post_createFounder.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case FounderActionTypes.get_founderList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FounderActionTypes.get_founderList.SUCCESS:
            return {
                ...state,
                loading: false,
                founderList: payload,
            };
        case FounderActionTypes.get_founderList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case FounderActionTypes.delete_founder.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FounderActionTypes.delete_founder.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteFounder: payload,
            };
        case FounderActionTypes.delete_founder.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case FounderActionTypes.put_updateFounderDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FounderActionTypes.put_updateFounderDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                updateFounder: payload,
            };
        case FounderActionTypes.put_updateFounderDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case FounderActionTypes.post_addFounderToCompany.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FounderActionTypes.post_addFounderToCompany.SUCCESS:
            return {
                ...state,
                loading: false,
                addFounderToCompany: payload,
            };
        case FounderActionTypes.post_addFounderToCompany.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case FounderActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
