import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

import { Refresh } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import {
  GetClassListAction,
  PutClassUpdateAction,
} from "./actions/ClassActions";
import AddEditClassModal from "./Modal/ClassAddEditModal";
import DeleteClassModal from "./Modal/DeleteClassModal";

class ClassList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      classId: 0,
      openAddAddEditClassModal: false,
      classDetails: "",
      openDeleteClassModal: false,
      type: "",
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetClassListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.classList !== undefined &&
      this.props.classList !== null &&
      this.props.classList !== prevProps.classList
    ) {
      this.setState({
        data: this.props.classList,
      });
    }
  };

  handleEditClassDetails = (event, rowData) => {
    let classId = rowData.id;
    this.setState({
      classId: classId,
      classDetails: rowData,
      openAddAddEditClassModal: true,
      type: "edit",
    });
  };

  handleDeleteClass = (event, rowData) => {
    let classId = rowData.id;
    this.setState({
      classId: classId,
      classDetails: rowData,
      openDeleteClassModal: true,
    });
  };

  handleDeactivateClass = (event, rowData) => {
    let classId = rowData.id;
    this.setState({
      classId: classId,
      classDetails: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      openAddAddEditClassModal: false,
      openDeleteClassModal: false,
      classId: 0,
      classDetails: "",
    });
  };

  addClassClickHandler = () => {
    this.setState({
      openAddAddEditClassModal: true,
      type: "add",
    });
  };

  handleClassStatus = (e, rowData) => {
    let obj = {
      isVisible: !rowData.isVisible,
    };
    this.props.PutClassUpdateAction(obj, rowData.id);
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      openAddAddEditClassModal,
      openDeleteClassModal,
      classId,
      classDetails,
      type,
    } = this.state;

    return (
      <>
        {openAddAddEditClassModal && (
          <AddEditClassModal
            classId={classId}
            classDetails={classDetails}
            type={type}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteClassModal && (
          <DeleteClassModal
            classId={classId}
            classDetails={classDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Class List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Class Name",
                      field: "name",
                    },
                    {
                      title: "Company Count",
                      field: "companyCount",
                    },
                    {
                      title: "Visibility Status",
                      field: "isVisible",
                      render: (rowData) =>
                        rowData.isVisible === true ? (
                          <span style={{ color: "green" }}> Active </span>
                        ) : (
                          <span style={{ color: "red" }}> Inactive </span>
                        ),
                    },
                  ]}
                  isLoading={loading}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Create Class",
                      isFreeAction: true,
                      onClick: () => this.addClassClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Update Class Details",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditClassDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Class",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : rowData.isactive == "Y"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteClass(event, rowData),
                    }),
                    (rowData) => ({
                      icon:
                        rowData.isVisible === true
                          ? CancelIcon
                          : CheckCircleOutlineIcon,
                      tooltip:
                        rowData.isVisible === true
                          ? "Block Class Visibility"
                          : "Approve Class Visibility",
                      onClick: (event, rowData) =>
                        this.handleClassStatus(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

ClassList = reduxForm({
  form: "ClassListForm",
  enableReinitialize: true,
})(ClassList);

function mapStateToProps(state) {
  return {
    loading: state.classReducer.loading,
    classList:
      state.classReducer.classList !== null &&
      state.classReducer.classList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetClassListAction,
        PutClassUpdateAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ClassList));
