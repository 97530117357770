import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import ViewListIcon from "@material-ui/icons/ViewList";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {
  PostGenerateMagicTokenAction,
  PostGenerateMagicTokenScaleUpAction,
} from "./actions/PitchDayActions";
import {
  renderTextField,
  renderSelectField,
} from "../../../shared/reduxFields";
import { MenuItem } from "@material-ui/core";

class GenerateMagicToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      scaleupData: [],
      eventType: "",
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.magicUrl !== undefined &&
      this.props.magicUrl !== null &&
      this.props.magicUrl !== prevProps.magicUrl
    ) {
      this.setState({
        data: this.props.magicUrl.data,
      });
    }
    if (
      this.props.magicUrlScaleup !== undefined &&
      this.props.magicUrlScaleup !== null &&
      this.props.magicUrlScaleup !== prevProps.magicUrlScaleup
    ) {
      this.setState({
        scaleupData: this.props.magicUrlScaleup.data,
      });
    }
  };

  onFormSubmitHandler = (formData) => {
    let postData = {
      email: formData.email,
    };
    let eventType = this.state.eventType;
    if (eventType === "PD") {
      this.props.PostGenerateMagicTokenAction(postData);
    } else {
      this.props.PostGenerateMagicTokenScaleUpAction(postData);
    }
  };

  resetHandler = () => {
    this.props.reset("GenerateMagicTokenForm");
  };

  onEventChangeHandler = (e) => {
    this.setState({
      eventType: e.target.value,
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { data, scaleupData } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Generate Magic Link</h4>
              </CardHeader>
              <CardBody>
                <form
                  onSubmit={handleSubmit((val) =>
                    this.onFormSubmitHandler(val)
                  )}
                >
                  <GridContainer>
                    <GridItem xs={12} md={6}>
                      <Field
                        id="selectType"
                        name="selectType"
                        label="Select Event"
                        required={true}
                        onChange={(e) => this.onEventChangeHandler(e)}
                        component={renderSelectField}
                      >
                        <MenuItem key="SU" value="SU">
                          Scale Up Day
                        </MenuItem>
                        <MenuItem key="PD" value="PD">
                          Pitch Day
                        </MenuItem>
                      </Field>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <Field
                        id="email"
                        name="email"
                        label="Enter Email"
                        required={true}
                        component={renderTextField}
                      />
                    </GridItem>
                  </GridContainer>
                  <Button type="submit" color="rose">
                    SUBMIT
                  </Button>
                  <Button onClick={() => this.resetHandler()}>CLEAR</Button>
                </form>
                {data.magicUrl && (
                  <GridContainer>
                    <span style={{ marginTop: "20px" }}>
                      {" "}
                      <b>Magic URL : </b> {data.magicUrl}{" "}
                    </span>
                  </GridContainer>
                )}
                {scaleupData.scaleUpMagicUrl && (
                  <GridContainer>
                    <span style={{ marginTop: "20px" }}>
                      {" "}
                      <b>Scale Up Magic URL : </b> {scaleupData.scaleUpMagicUrl}{" "}
                    </span>
                  </GridContainer>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

GenerateMagicToken = reduxForm({
  form: "GenerateMagicTokenForm",
  enableReinitialize: true,
})(GenerateMagicToken);

function mapStateToProps(state) {
  return {
    loading: state.pitchDayReducer.loading,
    magicUrl:
      state.pitchDayReducer.magicToken !== null &&
      state.pitchDayReducer.magicToken,
    magicUrlScaleup:
      state.pitchDayReducer.magicTokenScaleUp !== null &&
      state.pitchDayReducer.magicTokenScaleUp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        PostGenerateMagicTokenAction,
        PostGenerateMagicTokenScaleUpAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GenerateMagicToken));
