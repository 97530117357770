/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_CLASS_API = `${API_URL}/class/add`;
export const GET_CLASS_LIST_API = `${API_URL}/class/get`;
export const PUT_UPDATE_CLASS_API = `${API_URL}/class/update`;
export const DELETE_CLASS_API = `${API_URL}/class/delete`;

export const ClassActionTypes = {
    post_createClass: createRequestActionTypes('POST_CREATE_CLASS'),
    get_classList: createRequestActionTypes('GET_CLASS_LIST'),
    put_updateClassDetail: createRequestActionTypes('UPDATE_CLASS_DETAIL'),
    delete_class: createRequestActionTypes('DELETE_CLASS'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};