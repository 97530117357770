
/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    SectorActionTypes,
    POST_CREATE_SECTOR_API,
    GET_SECTOR_LIST_API,
    PUT_UPDATE_SECTOR_API,
    DELETE_SECTOR_API,
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateSectorAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(SectorActionTypes.post_createSector.REQUEST));
        fetch(`${POST_CREATE_SECTOR_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(SectorActionTypes.post_createSector.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(SectorActionTypes.post_createSector.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(SectorActionTypes.post_createSector.SUCCESS, response));
                    dispatch(reset('AddEditSectorModalForm'));
                    showSuccessMessage('Sector Created Successfully');
                    dispatch(GetSectorListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(SectorActionTypes.post_createSector.FAILURE));
                console.log('error post_createSector ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(SectorActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetSectorListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(SectorActionTypes.get_sectorList.REQUEST));
        fetch(`${GET_SECTOR_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(SectorActionTypes.get_sectorList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(SectorActionTypes.get_sectorList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(SectorActionTypes.get_sectorList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(SectorActionTypes.get_sectorList.FAILURE));
                console.log('error get_sectorList ..', error);
            });
    };
};

export const PutSectorUpdateAction = (postData, sectorId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(SectorActionTypes.put_updateSectorDetail.REQUEST));
        fetch(`${PUT_UPDATE_SECTOR_API}/${sectorId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(SectorActionTypes.put_updateSectorDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(SectorActionTypes.put_updateSectorDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(SectorActionTypes.put_updateSectorDetail.SUCCESS, response));
                    showSuccessMessage('Sector Details Updated Successfully');
                    dispatch(GetSectorListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(SectorActionTypes.put_updateSectorDetail.FAILURE));
                console.log('error put_updateSectorDetail ..', error);
            });
    };
};

export const DeleteSectorAction = (sectorId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(SectorActionTypes.delete_sector.REQUEST));
        fetch(`${DELETE_SECTOR_API}/${sectorId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(SectorActionTypes.delete_sector.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(SectorActionTypes.delete_sector.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(SectorActionTypes.delete_sector.SUCCESS, response));
                    showSuccessMessage('Sector Deleted Successfully');
                    dispatch(GetSectorListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(SectorActionTypes.delete_sector.FAILURE));
                console.log('error delete_sector ..', error);
            });
    };
};

