/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_STATUS_API = `${API_URL}/status/add`;
export const GET_STATUS_LIST_API = `${API_URL}/status/get`;
export const PUT_UPDATE_STATUS_API = `${API_URL}/status/update`;
export const DELETE_STATUS_API = `${API_URL}/status/delete`;

export const StatusActionTypes = {
    post_createStatus: createRequestActionTypes('POST_CREATE_STATUS'),
    get_statusList: createRequestActionTypes('GET_STATUS_LIST'),
    put_updateStatusDetail: createRequestActionTypes('UPDATE_STATUS_DETAIL'),
    delete_status: createRequestActionTypes('DELETE_STATUS'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};