import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { PutInvestorUpdateAction, PostCreateInvestorAction, ResetErrorDataAction } from '../actions/InvestorsActions';
import { renderTextField } from "../../../../shared/reduxFields";
import { showErrorMessage } from '../../../../shared/utility';
import validate from './InvestorsEditModalValidate';



class AddEditInvestorModal extends Component {

    componentDidMount = () => {
        if(this.props.type === "edit") {
            let investorDetails = this.props.investorDetails;
            this.props.dispatch(change('AddEditInvestorModalForm', 'firstName', investorDetails.firstName));
            this.props.dispatch(change('AddEditInvestorModalForm', 'lastName', investorDetails.lastName));
            this.props.dispatch(change('AddEditInvestorModalForm', 'email', investorDetails.email));
            this.props.dispatch(change('AddEditInvestorModalForm', 'linkedin', investorDetails.linkedinProfile));
            this.props.dispatch(change('AddEditInvestorModalForm', 'about', investorDetails.about));
            this.props.dispatch(change('AddEditInvestorModalForm', 'countryCode', investorDetails.countryCode));
            this.props.dispatch(change('AddEditInvestorModalForm', 'phoneNumber', investorDetails.phoneNumber));
            // this.props.dispatch(change('AddEditInvestorModalForm', 'type', investorDetails.typeOfInvestor));
        }
    }

    onFormSubmitHandler = (formData) => {
        const investorDetails = formData
        const postData = {
            "firstName": investorDetails.firstName,
            "lastName": investorDetails.lastName,
            "email": formData.email,
            "linkedinProfile": investorDetails.linkedin,
            "about": formData.about,
            "phoneNumber": investorDetails.phoneNumber,
            "typeOfInvestor": investorDetails.type,
            "countryCode": investorDetails.countryCode,
        }
        if(this.props.type === "add") {
            this.props.PostCreateInvestorAction(postData, this.props.type);
        } else {
            this.props.PutInvestorUpdateAction(postData, this.props.investorId, true, false);
        }
        this.props.handleClose();
    }

    render() {
        const { classes, handleSubmit, loading, type, investorDetails } = this.props;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>{type === "add" ? "Add" : "Edit"} Investor Details</h6>
                                </MuiDialogTitle>
                                <Divider />
                                <MuiDialogContent>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Field
                                                id="firstName"
                                                name="firstName"
                                                label="Enter First Name"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="lastName"
                                                name="lastName"
                                                label="Enter Last Name"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="email"
                                                name="email"
                                                label="Enter Email"
                                                required={true}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="about"
                                                name="about"
                                                label="Enter Description"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="linkedin"
                                                name="linkedin"
                                                label="LinkedIn"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="countryCode"
                                                name="countryCode"
                                                label="Country Code ( Do not add +, - )"
                                                required={true}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                label="Phone Number"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        {/* <GridItem xs={12}>
                                            <Field
                                                id="Type"
                                                name="Type"
                                                label="Investor Type"
                                                required={false}
                                                component={renderSelectField}
                                            >
                                                
                                            </Field>
                                        </GridItem> */}
                                    </GridContainer>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple type="submit" color="rose">
                                        SUBMIT
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

AddEditInvestorModal = reduxForm({
    form: "AddEditInvestorModalForm",
    validate,
    enableReinitialize: true
})(AddEditInvestorModal);


function mapStateToProps(state) {
    return {
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PutInvestorUpdateAction,
            ResetErrorDataAction,
            PostCreateInvestorAction
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AddEditInvestorModal));
