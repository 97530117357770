
/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    DomainsActionTypes,
    POST_CREATE_DOMAINS_API,
    GET_DOMAINS_LIST_API,
    PUT_UPDATE_DOMAINS_API,
    DELETE_DOMAINS_API,
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateDomainsAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(DomainsActionTypes.post_createDomains.REQUEST));
        fetch(`${POST_CREATE_DOMAINS_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(DomainsActionTypes.post_createDomains.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(DomainsActionTypes.post_createDomains.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(DomainsActionTypes.post_createDomains.SUCCESS, response));
                    dispatch(reset('AddEditDomainsModalForm'));
                    showSuccessMessage('Domains Created Successfully');
                    dispatch(GetDomainsListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(DomainsActionTypes.post_createDomains.FAILURE));
                console.log('error post_createDomains ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(DomainsActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetDomainsListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(DomainsActionTypes.get_domainsList.REQUEST));
        fetch(`${GET_DOMAINS_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(DomainsActionTypes.get_domainsList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(DomainsActionTypes.get_domainsList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(DomainsActionTypes.get_domainsList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(DomainsActionTypes.get_domainsList.FAILURE));
                console.log('error get_domainsList ..', error);
            });
    };
};

export const PutDomainsUpdateAction = (postData, domainsId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(DomainsActionTypes.put_updateDomainsDetail.REQUEST));
        fetch(`${PUT_UPDATE_DOMAINS_API}/${domainsId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(DomainsActionTypes.put_updateDomainsDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(DomainsActionTypes.put_updateDomainsDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(DomainsActionTypes.put_updateDomainsDetail.SUCCESS, response));
                    showSuccessMessage('Domains Details Updated Successfully');
                    dispatch(GetDomainsListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(DomainsActionTypes.put_updateDomainsDetail.FAILURE));
                console.log('error put_updateDomainsDetail ..', error);
            });
    };
};

export const DeleteDomainsAction = (domainsId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(DomainsActionTypes.delete_domains.REQUEST));
        fetch(`${DELETE_DOMAINS_API}/${domainsId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(DomainsActionTypes.delete_domains.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(DomainsActionTypes.delete_domains.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(DomainsActionTypes.delete_domains.SUCCESS, response));
                    showSuccessMessage('Domains Deleted Successfully');
                    dispatch(GetDomainsListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(DomainsActionTypes.delete_domains.FAILURE));
                console.log('error delete_domains ..', error);
            });
    };
};

