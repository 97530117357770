
/**
 @desc
 * Centralized unique actions for company Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    CompanyActionTypes,
    POST_CREATE_COMPANY_API,
    GET_COMPANY_LIST_API,
    PUT_UPDATE_COMPANY_API,
    DELETE_COMPANY_API,
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateCompanyAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(CompanyActionTypes.post_createCompany.REQUEST));
        fetch(`${POST_CREATE_COMPANY_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(CompanyActionTypes.post_createCompany.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(CompanyActionTypes.post_createCompany.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(CompanyActionTypes.post_createCompany.SUCCESS, response));
                    dispatch(reset('AddEditCompanyModalForm'));
                    showSuccessMessage('Company Created Successfully');
                    dispatch(GetCompanyListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(CompanyActionTypes.post_createCompany.FAILURE));
                console.log('error post_createCompany ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(CompanyActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetCompanyListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(CompanyActionTypes.get_companyList.REQUEST));
        fetch(`${GET_COMPANY_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(CompanyActionTypes.get_companyList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(CompanyActionTypes.get_companyList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(CompanyActionTypes.get_companyList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(CompanyActionTypes.get_companyList.FAILURE));
                console.log('error get_companyList ..', error);
            });
    };
};

export const PutCompanyUpdateAction = (postData, companyId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(CompanyActionTypes.put_updateCompanyDetail.REQUEST));
        fetch(`${PUT_UPDATE_COMPANY_API}/${companyId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(CompanyActionTypes.put_updateCompanyDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(CompanyActionTypes.put_updateCompanyDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(CompanyActionTypes.put_updateCompanyDetail.SUCCESS, response));
                    showSuccessMessage('Company Details Updated Successfully');
                    dispatch(GetCompanyListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(CompanyActionTypes.put_updateCompanyDetail.FAILURE));
                console.log('error put_updateCompanyDetail ..', error);
            });
    };
};

export const DeleteCompanyAction = (companyId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(CompanyActionTypes.delete_company.REQUEST));
        fetch(`${DELETE_COMPANY_API}/${companyId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(CompanyActionTypes.delete_company.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(CompanyActionTypes.delete_company.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(CompanyActionTypes.delete_company.SUCCESS, response));
                    showSuccessMessage('Company Deleted Successfully');
                    dispatch(GetCompanyListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(CompanyActionTypes.delete_company.FAILURE));
                console.log('error delete_company ..', error);
            });
    };
};

