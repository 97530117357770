import React, { Component } from "react";
import "date-fns";
import uuidv1 from "uuid/v1";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import {
  renderSelectField,
  renderDatePicker,
  renderTimePicker,
  renderTextField,
} from "../../../../shared/reduxFields";
import {
  PostCreatePitchEventsAction,
  PatchUpdatePitchEventsAction,
} from "../actions/PitchDayActions";
// import validate from './SectorEditModalValidate';

class AddEditPitchEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      selectedTime: null,
      selectedDateScaleUp: null,
      selectedTimeScaleUp: null,
    };
  }

  componentDidMount = () => {
    if (this.props.type === "edit") {
      let pitchEventDetails = this.props.pitchEventDetails;
      this.setState(
        {
          selectedDate: pitchEventDetails.pitchDayDetails[0].date,
          selectedTime: pitchEventDetails.pitchDayDetails[0].time,
          selectedDateScaleUp: pitchEventDetails.pitchDayDetails[0].scaleUpDate,
          selectedTimeScaleUp: pitchEventDetails.pitchDayDetails[0].scaleUpTime,
        },
        () => {
          this.props.dispatch(
            change(
              "AddEditPitchEventModalForm",
              "date",
              pitchEventDetails.pitchDayDetails[0].date
            )
          );
          this.props.dispatch(
            change(
              "AddEditPitchEventModalForm",
              "time",
              pitchEventDetails.pitchDayDetails[0].time
            )
          );
          this.props.dispatch(
            change("AddEditPitchEventModalForm", "name", pitchEventDetails.name)
          );
          this.props.dispatch(
            change(
              "AddEditPitchEventModalForm",
              "suname",
              pitchEventDetails.scaleUpName
            )
          );
          this.props.dispatch(
            change(
              "AddEditPitchEventModalForm",
              "sudate",
              pitchEventDetails.pitchDayDetails[0].scaleUpDate
            )
          );
          this.props.dispatch(
            change(
              "AddEditPitchEventModalForm",
              "sutime",
              pitchEventDetails.pitchDayDetails[0].scaleUpTime
            )
          );
        }
      );
    }
  };

  onFormSubmitHandler = (formData) => {
    let pitchDaysData = [];
    let scaleupDaysData = [];
    let tempObj = {};
    let scaleupTempObj = {};
    let selectedDate = formData.date;
    let selectedTime = formData.time;
    tempObj["date"] = selectedDate;
    tempObj["time"] = selectedTime;
    pitchDaysData.push(tempObj);
    const postData = {
      name: formData.name,
      pitchDays: pitchDaysData,
    };
    if (this.props.type === "add") {
      (postData["pitchEventId"] = uuidv1()),
        this.props.PostCreatePitchEventsAction(postData);
    } else {
      pitchDaysData.map((obj) => {
        obj.pitchDayId = this.props.pitchEventDetails.pitchDayDetails[0].pitchDayId;
      });
      scaleupTempObj["scaleUpDate"] = formData.sudate;
      scaleupTempObj["scaleUpTime"] = formData.sutime;
      scaleupTempObj["pitchDayId"] = this.props.pitchEventDetails.id;
      scaleupDaysData.push(scaleupTempObj);
      postData["pitchDays"] = pitchDaysData;
      postData["scaleUpName"] = formData.suname;
      postData["scaleUpDays"] = scaleupDaysData;
      postData["pitchEventId"] = this.props.pitchEventDetails.pitchEventId;
      this.props.PatchUpdatePitchEventsAction(
        postData,
        this.props.pitchEventDetails.pitchEventId
      );
    }
    this.props.handleClose();
  };

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };

  handleDateChangeScaleup = (date) => {
    this.setState({
      selectedDateScaleUp: date,
    });
  };

  handleTimeChange = (time) => {
    this.setState({
      selectedTime: time,
    });
  };

  handleTimeChangeScaleup = (time) => {
    this.setState({
      selectedTimeScaleUp: time,
    });
  };

  render() {
    const { classes, handleSubmit, type } = this.props;
    const {
      selectedDate,
      selectedTime,
      selectedDateScaleUp,
      selectedTimeScaleUp,
    } = this.state;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Dialog
              onClose={() => this.props.handleClose()}
              aria-labelledby="customized-dialog-title"
              open={() => this.props.handleOpen()}
            >
              <form
                onSubmit={handleSubmit((val) => this.onFormSubmitHandler(val))}
              >
                <MuiDialogTitle
                  className={classes.modelTitle}
                  id="customized-dialog-title"
                  onClose={() => this.props.handleClose()}
                >
                  <h6>{type === "add" ? "Add" : "Edit"} Pitch Event</h6>
                </MuiDialogTitle>
                <Divider />
                <MuiDialogContent>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Field
                        id="name"
                        name="name"
                        label="Pitch Event Name"
                        required={true}
                        component={renderTextField}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <Field
                        id="date"
                        name="date"
                        label="Pitch Date"
                        required={true}
                        value={selectedDate}
                        onChange={(e) => this.handleDateChange(e)}
                        component={renderDatePicker}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <Field
                        id="time"
                        name="time"
                        label="Pitch Time"
                        required={true}
                        value={selectedDate}
                        onChange={(e) => this.handleTimeChange(e)}
                        component={renderTimePicker}
                      />
                    </GridItem>
                    {type === "edit" && (
                      <>
                        <GridItem xs={12}>
                          <Field
                            id="suname"
                            name="suname"
                            label="Scaleup Name"
                            required={true}
                            component={renderTextField}
                          />
                        </GridItem>

                        <GridItem xs={6}>
                          <Field
                            id="sudate"
                            name="sudate"
                            label="Scaleup Date"
                            value={selectedDateScaleUp}
                            onChange={(e) => this.handleDateChangeScaleup(e)}
                            component={renderDatePicker}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <Field
                            id="sutime"
                            name="sutime"
                            label="Scaleup Time"
                            value={selectedTimeScaleUp}
                            onChange={(e) => this.handleTimeChangeScaleup(e)}
                            component={renderTimePicker}
                          />
                        </GridItem>
                        <GridItem xs={6}>
                          <Field
                            id="status"
                            name="status"
                            label="isActive"
                            onChange={(e) => this.handleStatusChange(e)}
                            component={renderSelectField}
                          >
                            <MenuItem key="true" value="true">
                              True
                            </MenuItem>
                            <MenuItem key="false" value="false">
                              False
                            </MenuItem>
                          </Field>
                        </GridItem>
                      </>
                    )}
                  </GridContainer>
                </MuiDialogContent>
                <MuiDialogActions>
                  <Button type="submit" color="rose">
                    SUBMIT
                  </Button>
                  <Button onClick={() => this.props.handleClose()}>
                    CLOSE
                  </Button>
                </MuiDialogActions>
              </form>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddEditPitchEventModal = reduxForm({
  form: "AddEditPitchEventModalForm",
  // validate,
  enableReinitialize: true,
})(AddEditPitchEventModal);

function mapStateToProps(state) {
  return {
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        PostCreatePitchEventsAction,
        PatchUpdatePitchEventsAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddEditPitchEventModal));
