import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Divider from "@material-ui/core/Divider";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { renderTextField } from "../../../shared/reduxFields";
import { PostChangeCurrentUserPassword } from '../actions/LoginActions';
import validate from './changePasswordValidate';

class ChangePassword extends Component {

    onFormSubmitHandler = (formData) => {
        let postData = {
            "oldPassword": formData.currentPassword,
            "newPassword": formData.newPassword
        }
        this.props.PostChangeCurrentUserPassword(postData);
        this.props.handleClose();
    }

    render() {
        const { classes, handleSubmit } = this.props;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                <h6>Change Password</h6>
                                <ul style={{ fontSize: '12px' }}>
                                    <li>Password should be of 8 characters minimum and 20 characters maximum</li>
                                    <li>Password should must contain atleast 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Character</li>
                                </ul>
                            </MuiDialogTitle>
                            <Divider />
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogContent>
                                    <Field
                                        id="currentPassword"
                                        name="currentPassword"
                                        label="Current Password"
                                        type="password"
                                        required={true}
                                        component={renderTextField}
                                    />
                                    <Field
                                        id="newPassword"
                                        name="newPassword"
                                        label="New Password"
                                        required={true}
                                        type="password"
                                        inputProps={{
                                            maxLength: 20,
                                            minLength: 8
                                        }}
                                        component={renderTextField}
                                    />
                                    <Field
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        required={true}
                                        inputProps={{
                                            maxLength: 20,
                                            minLength: 8
                                        }}
                                        component={renderTextField}
                                    />
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button color="rose" type='submit'>
                                        SUBMIT
                                    </Button>
                                    <Button onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

ChangePassword = reduxForm({
    form: "ChangePasswordForm",
    validate,
    enableReinitialize: true
})(ChangePassword);


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PostChangeCurrentUserPassword
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ChangePassword));
