import { InvestorActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createInvestor: null,
    investorList: null,
    updateInvestor: null,
    investedCompaniesList: null,
    approveInvestedCompaniesByInvestorList: null,
    companiesLikedByInvestor: null,
    deleteInvestor: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case InvestorActionTypes.post_createInvestor.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorActionTypes.post_createInvestor.SUCCESS:
            return {
                ...state,
                loading: false,
                createInvestor: payload,
            };
        case InvestorActionTypes.post_createInvestor.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.SUCCESS:
            return {
                ...state,
                loading: false,
                approveInvestedCompaniesByInvestorList: payload,
            };
        case InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorActionTypes.get_companiesLikedByInvestorList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorActionTypes.get_companiesLikedByInvestorList.SUCCESS:
            return {
                ...state,
                loading: false,
                companiesLikedByInvestor: payload,
            };
        case InvestorActionTypes.get_companiesLikedByInvestorList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorActionTypes.get_investorList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorActionTypes.get_investorList.SUCCESS:
            return {
                ...state,
                loading: false,
                investorList: payload,
            };
        case InvestorActionTypes.get_investorList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorActionTypes.get_investedCompaniesList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorActionTypes.get_investedCompaniesList.SUCCESS:
            return {
                ...state,
                loading: false,
                investedCompaniesList: payload,
            };
        case InvestorActionTypes.get_investedCompaniesList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorActionTypes.delete_investor.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorActionTypes.delete_investor.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteInvestor: payload,
            };
        case InvestorActionTypes.delete_investor.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorActionTypes.put_updateInvestorDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case InvestorActionTypes.put_updateInvestorDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                updateInvestor: payload,
            };
        case InvestorActionTypes.put_updateInvestorDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case InvestorActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
