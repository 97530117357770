import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import BlockIcon from "@material-ui/icons/Block";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { GetFounderListAction } from "./actions/FoundersActions";
import ImageViewer from "../../../shared/ImageViewer";
import AddEditFounderModal from "./Modal/FoundersAddEditModal";
import DeleteFounderModal from "./Modal/DeleteFoundersModal";

class FounderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      founderId: 0,
      orderId: 0,
      openImageViewer: false,
      imgsArray: [],
      openAddAddEditFounderModal: false,
      founderDetails: "",
      openDeleteFounderModal: false,
      type: "",
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetFounderListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.founderList !== undefined &&
      this.props.founderList !== null &&
      this.props.founderList !== prevProps.founderList
    ) {
      this.setState({
        data: this.props.founderList,
      });
    }
  };

  ImageClickHandler = (rowData) => {
    let ImageArr = [];
    let ImgObj = {};
    ImgObj["src"] = rowData.image;
    ImageArr.push(ImgObj);
    this.setState({
      openImageViewer: true,
      imgsArray: ImageArr,
    });
  };

  closeImageViewer = () => {
    this.setState({
      openImageViewer: false,
      imgsArray: [],
    });
  };

  handleEditFounderDetails = (event, rowData) => {
    let founderId = rowData.id;
    this.setState({
      founderId: founderId,
      orderId: rowData.sortorder,
      founderDetails: rowData,
      openAddAddEditFounderModal: true,
      type: "edit",
    });
  };

  handleDeleteFounder = (event, rowData) => {
    let founderId = rowData.id;
    this.setState({
      founderId: founderId,
      founderDetails: rowData,
      openDeleteFounderModal: true,
    });
  };

  handleDeactivateFounder = (event, rowData) => {
    let founderId = rowData.id;
    this.setState({
      founderId: founderId,
      founderDetails: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      openAddAddEditFounderModal: false,
      openDeleteFounderModal: false,
      founderId: 0,
      orderId: 0,
      founderDetails: "",
    });
  };

  addFounderClickHandler = () => {
    this.setState({
      openAddAddEditFounderModal: true,
      type: "add",
    });
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      openAddAddEditFounderModal,
      openDeleteFounderModal,
      founderId,
      orderId,
      founderDetails,
      imgsArray,
      openImageViewer,
      type,
    } = this.state;

    return (
      <>
        {openAddAddEditFounderModal && (
          <AddEditFounderModal
            founderId={founderId}
            orderId={orderId}
            founderDetails={founderDetails}
            type={type}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteFounderModal && (
          <DeleteFounderModal
            founderId={founderId}
            founderDetails={founderDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        {openImageViewer && (
          <ImageViewer
            imgsArray={imgsArray}
            isOpen={openImageViewer}
            closeImgsViewer={() => this.closeImageViewer()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Founders List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Image",
                      field: "image",
                      filtering: false,
                      render: (rowData) => (
                        <img
                          src={rowData.image}
                          style={{
                            width: "100px",
                            height: "100px",
                            widthborderRadius: "10%",
                            cursor: "pointer",
                          }}
                          alt="founder"
                          onClick={() => this.ImageClickHandler(rowData)}
                        />
                      ),
                    },
                    {
                      title: "First Name",
                      field: "firstName",
                    },
                    {
                      title: "Last Name",
                      field: "lastName",
                    },
                    {
                      title: "Short Bio",
                      field: "shortBio",
                    },
                    {
                      title: "Email",
                      field: "email",
                    },
                    {
                      title: "Designation",
                      field: "designation",
                    },
                    {
                      title: "LinkedIn",
                      field: "linkedln",
                      render: (rowData) =>
                        rowData.linkedln !== null && rowData.linkedln !== "" ? (
                          <span>
                            <a
                              href={rowData.linkedln}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              Link{" "}
                            </a>{" "}
                          </span>
                        ) : (
                          "NA"
                        ),
                    },
                    {
                      title: "Twitter",
                      field: "twitter",
                      render: (rowData) =>
                        rowData.twitter !== null && rowData.twitter !== "" ? (
                          <span>
                            <a
                              href={rowData.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              Link{" "}
                            </a>{" "}
                          </span>
                        ) : (
                          "NA"
                        ),
                    },

                    // {
                    //     title: 'Status',
                    //     field: 'isactive',
                    //     render: rowData => rowData.isactive == "Y"
                    //     ? <span style={{ color: 'green' }}><b>Active</b></span>
                    //     : <span style={{ color: 'red' }}><b>In-Active</b></span>
                    // }
                  ]}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  isLoading={loading}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Create Founder",
                      isFreeAction: true,
                      onClick: () => this.addFounderClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Update Founder Details",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditFounderDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Founder",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : rowData.isactive == "Y"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteFounder(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

FounderList = reduxForm({
  form: "FounderListForm",
  enableReinitialize: true,
})(FounderList);

function mapStateToProps(state) {
  return {
    loading: state.founderReducer.loading,
    founderList:
      state.founderReducer.founderList !== null &&
      state.founderReducer.founderList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetFounderListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FounderList));
