import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import { PostCreatePitchEventVideoAction } from "../actions/PitchDayActions";
import { renderTextField } from "../../../../shared/reduxFields";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Loader from "../../../../components/Loader";

class AddEditVideoModal extends Component {
  componentDidMount = () => {
    this.props.dispatch(
      change(
        "AddEditVideoModalForm",
        "pitchVideo",
        this.props?.pitchEventDetails?.pitchDayVideo?.videoLink
      )
    );
  };

  onFormSubmitHandler = (formData) => {
    let postData = {
      videoLink: formData.pitchVideo,
    };
    this.props.PostCreatePitchEventVideoAction(
      postData,
      this.props.pitchEventId
    );
    this.props.handleClose();
  };

  render() {
    const { loading, classes, handleSubmit, companyData } = this.props;
    return (
      <div className={classes.container}>
        {loading ? (
          <Loader open={true} loaderMessage="Fetching..." />
        ) : (
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <Dialog
                onClose={() => this.props.handleClose()}
                aria-labelledby="customized-dialog-title"
                open={() => this.props.handleOpen()}
                fullWidth
              >
                <form
                  onSubmit={handleSubmit((val) =>
                    this.onFormSubmitHandler(val)
                  )}
                >
                  <MuiDialogTitle
                    className={classes.modelTitle}
                    id="customized-dialog-title"
                    onClose={() => this.props.handleClose()}
                  >
                    <h6>Pitch Day Video</h6>
                  </MuiDialogTitle>
                  <Divider />
                  <MuiDialogContent>
                    <GridContainer>
                      <GridItem xs={12} md={12}>
                        <Field
                          id="pitchVideo"
                          name="pitchVideo"
                          label="Pitch Video"
                          fullWidth={true}
                          multiline={true}
                          rows={25}
                          component={renderTextField}
                        />
                      </GridItem>
                    </GridContainer>
                  </MuiDialogContent>
                  <MuiDialogActions>
                    <Button type="submit" color="rose">
                      SUBMIT
                    </Button>
                    <Button onClick={() => this.props.handleClose()}>
                      CLOSE
                    </Button>
                  </MuiDialogActions>
                </form>
              </Dialog>
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}

AddEditVideoModal = reduxForm({
  form: "AddEditVideoModalForm",
  enableReinitialize: true,
})(AddEditVideoModal);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        PostCreatePitchEventVideoAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddEditVideoModal));
