import React, { Component } from "react";
import moment from "moment";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import {
  GetInvestorListAction,
  PutInvestorUpdateAction,
} from "./actions/InvestorsActions";
import AddEditInvestorModal from "./Modal/InvestorsAddEditModal";
import DeleteInvestorModal from "./Modal/DeleteInvestorsModal";

class InvestorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      investorId: 0,
      openAddAddEditInvestorModal: false,
      investorDetails: "",
      openDeleteInvestorModal: false,
      type: "",
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetInvestorListAction(true, false);
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.InvestorList !== undefined &&
      this.props.InvestorList !== null &&
      this.props.InvestorList !== prevProps.InvestorList
    ) {
      this.setState({
        data: this.props.InvestorList,
      });
    }
  };

  handleEditInvestorDetails = (event, rowData) => {
    let investorId = rowData.id;
    this.setState({
      investorId: investorId,
      investorDetails: rowData,
      openAddAddEditInvestorModal: true,
      type: "edit",
    });
  };

  handleDeleteInvestor = (event, rowData) => {
    let investorId = rowData.id;
    this.setState({
      investorId: investorId,
      investorDetails: rowData,
      openDeleteInvestorModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      openAddAddEditInvestorModal: false,
      openDeleteInvestorModal: false,
      investorId: 0,
      investorDetails: "",
    });
  };

  handleVerifyInvestor = (event, rowData) => {
    let investorId = rowData.id;
    let postData = {
      verified: !rowData.verified,
    };
    this.props.PutInvestorUpdateAction(postData, investorId, true, false);
  };

  handleDisapproveInvestor = (event, rowData) => {
    let investorId = rowData.id;
    let postData = {
      approved: !rowData.approved,
    };
    this.props.PutInvestorUpdateAction(postData, investorId, true, false);
  };

  addInvestorClickHandler = () => {
    this.setState({
      openAddAddEditInvestorModal: true,
      type: "add",
    });
  };

  compare = (a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      openAddAddEditInvestorModal,
      openDeleteInvestorModal,
      investorId,
      investorDetails,
      type,
    } = this.state;

    return (
      <>
        {openAddAddEditInvestorModal && (
          <AddEditInvestorModal
            investorId={investorId}
            investorDetails={investorDetails}
            type={type}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteInvestorModal && (
          <DeleteInvestorModal
            isApproved={true}
            isVerified={false}
            investorId={investorId}
            investorDetails={investorDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Investors List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "First Name",
                      field: "firstName",
                    },
                    {
                      title: "Last Name",
                      field: "lastName",
                    },
                    {
                      title: "Created At",
                      field: "createdAt",
                      render: (rowData) =>
                        rowData.createdAt !== null
                          ? moment(rowData.createdAt).format("DD/MM/YYYY hh:mm")
                          : "--",
                    },
                    {
                      title: "Email",
                      field: "email",
                    },
                    {
                      title: "Attend Day",
                      field: "attendDay",
                      render: (rowData) =>
                        rowData.attendDay !== null
                          ? moment(rowData.attendDay).format("DD/MM/YYYY")
                          : "NA",
                    },
                    {
                      title: "Attend Time",
                      field: "attendTime",
                      render: (rowData) =>
                        rowData.attendTime !== null
                          ? moment(rowData.attendTime).format("hh:mm A")
                          : "NA",
                    },
                    {
                      title: "About",
                      field: "about",
                    },
                    {
                      title: "LinkedIn",
                      field: "linkedinProfile",
                      render: (rowData) => (
                        <span>
                          <a
                            href={rowData.linkedinProfile}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>{" "}
                        </span>
                      ),
                    },
                    {
                      title: "Phone Number",
                      field: "phoneNumber",
                      render: (rowData) =>
                        rowData.countryCode + "-" + rowData.phoneNumber,
                    },
                    {
                      title: "Type",
                      field: "typeOfInvestor",
                    },
                    {
                      title: "Approved",
                      field: "approved",
                      render: (rowData) =>
                        rowData.approved === true ? (
                          <span style={{ color: "green" }}>
                            <b>Yes</b>
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            <b>No</b>
                          </span>
                        ),
                    },
                    {
                      title: "Approved Date",
                      field: "approvedDate",
                      render: (rowData) =>
                        rowData.approvedDate !== null &&
                        rowData.approvedDate !== undefined
                          ? moment(rowData.approvedDate).format("DD/MM/YYYY")
                          : "--",
                    },
                    {
                      title: "Verified",
                      field: "verified",
                      render: (rowData) =>
                        rowData.verified === true ? (
                          <span style={{ color: "green" }}>
                            <b>Yes</b>
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            <b>No</b>
                          </span>
                        ),
                    },
                  ]}
                  data={data.sort((a, b) => this.compare(a, b))}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  isLoading={loading}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Create Investor",
                      isFreeAction: true,
                      onClick: () => this.addInvestorClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Update Investor Details",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditInvestorDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon:
                        rowData.verified === false
                          ? CheckCircleOutlineIcon
                          : CancelIcon,
                      tooltip:
                        rowData.verified === false
                          ? "Verify Investor"
                          : "Unverify Investor",
                      onClick: (event, rowData) =>
                        this.handleVerifyInvestor(event, rowData),
                    }),
                    (rowData) => ({
                      icon: CancelIcon,
                      tooltip: "Disapprove Investor",
                      onClick: (event, rowData) =>
                        this.handleDisapproveInvestor(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Investor",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : rowData.isactive == "Y" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteInvestor(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

InvestorList = reduxForm({
  form: "InvestorListForm",
  enableReinitialize: true,
})(InvestorList);

function mapStateToProps(state) {
  return {
    loading: state.investorReducer.loading,
    InvestorList:
      state.investorReducer.investorList !== null &&
      state.investorReducer.investorList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetInvestorListAction,
        PutInvestorUpdateAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InvestorList));
