/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
  actionCreator,
  checkHttpStatus,
  jsonApiHeader,
  showErrorMessage,
  PitchDayActionTypes,
  POST_GENERATE_MAGIC_TOKEN_API,
  POST_GENERATE_MAGIC_TOKEN_SCALE_UP_API,
  GET_ALL_PITCH_EVENTS_API,
  POST_CREATE_PITCH_EVENTS_API,
  PATCH_UPDATE_PITCH_EVENTS_API,
  GET_COMPANIES_FOR_PITCH_EVENTS_API,
  GET_INVESTOR_FOR_PITCH_EVENTS_API,
  UPDATE_COMPANIES_FOR_PITCH_EVENTS_API,
  POST_CREATE_PITCH_EVENTS_VIDEO_API,
  POST_CREATE_PITCH_EVENTS_LOCATION_API,
} from "../constants/index";
import {
  TOKEN_EXPIRY_MESSAGE,
  showSuccessMessage,
} from "../../../../shared/utility";
import { reset } from "redux-form";

export const PostGenerateMagicTokenAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(
      actionCreator(PitchDayActionTypes.post_generateMagicToken.REQUEST)
    );
    fetch(`${POST_GENERATE_MAGIC_TOKEN_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_generateMagicToken.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_generateMagicToken.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_generateMagicToken.SUCCESS,
              response
            )
          );
          dispatch(reset("GenerateMagicTokenForm"));
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.post_generateMagicToken.FAILURE)
        );
        console.log("error post_generateMagicToken ..", error);
      });
  };
};
export const PostGenerateMagicTokenScaleUpAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(PitchDayActionTypes.post_magicTokenScaleUp.REQUEST));
    fetch(`${POST_GENERATE_MAGIC_TOKEN_SCALE_UP_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_magicTokenScaleUp.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_magicTokenScaleUp.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_magicTokenScaleUp.SUCCESS,
              response
            )
          );
          dispatch(reset("GenerateMagicTokenForm"));
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.post_magicTokenScaleUp.FAILURE)
        );
        console.log("error post_magicTokenScaleUp ..", error);
      });
  };
};

export const PostCreatePitchEventVideoAction = (postData, pitchEventId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(
      actionCreator(PitchDayActionTypes.post_createPitchEventsVideo.REQUEST)
    );
    fetch(`${POST_CREATE_PITCH_EVENTS_VIDEO_API}/${pitchEventId}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEventsVideo.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEventsVideo.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEventsVideo.SUCCESS,
              response
            )
          );
          dispatch(GetAllPitchEventsAction());
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.post_createPitchEventsVideo.FAILURE)
        );
        console.log("error post_createPitchEventsVideo ..", error);
      });
  };
};
export const PostCreatePitchEventLocationAction = (postData, pitchEventId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(
      actionCreator(PitchDayActionTypes.post_createPitchEventsLocation.REQUEST)
    );
    fetch(`${POST_CREATE_PITCH_EVENTS_LOCATION_API}/${pitchEventId}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEventsLocation.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEventsLocation.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEventsLocation.SUCCESS,
              response
            )
          );
          showSuccessMessage("Location Added Successfully");
          dispatch(GetAllPitchEventsAction());
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(
            PitchDayActionTypes.post_createPitchEventsLocation.FAILURE
          )
        );
        console.log("error post_createPitchEventsLocation ..", error);
      });
  };
};

export const GetAllPitchEventsAction = () => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(PitchDayActionTypes.get_allPitchEvents.REQUEST));
    fetch(`${GET_ALL_PITCH_EVENTS_API}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_allPitchEvents.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_allPitchEvents.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_allPitchEvents.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(actionCreator(PitchDayActionTypes.get_allPitchEvents.FAILURE));
        console.log("error get_allPitchEvents ..", error);
      });
  };
};

export const PostCreatePitchEventsAction = (postData) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(actionCreator(PitchDayActionTypes.post_createPitchEvents.REQUEST));
    fetch(`${POST_CREATE_PITCH_EVENTS_API}`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEvents.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEvents.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.post_createPitchEvents.SUCCESS,
              response
            )
          );
          dispatch(GetAllPitchEventsAction());
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.post_createPitchEvents.FAILURE)
        );
        console.log("error post_createPitchEvents ..", error);
      });
  };
};

export const PatchUpdatePitchEventsAction = (postData, pitchEventId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(
      actionCreator(PitchDayActionTypes.patch_updatePitchEvents.REQUEST)
    );
    fetch(`${PATCH_UPDATE_PITCH_EVENTS_API}?id=${pitchEventId}`, {
      method: "PATCH",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.patch_updatePitchEvents.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.patch_updatePitchEvents.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.patch_updatePitchEvents.SUCCESS,
              response
            )
          );
          showSuccessMessage("Details Updated Successfully");
          dispatch(GetAllPitchEventsAction());
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.patch_updatePitchEvents.FAILURE)
        );
        console.log("error patch_updatePitchEvents ..", error);
      });
  };
};

export const GetAllCompaniesForEventsAction = (eventId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(
      actionCreator(PitchDayActionTypes.get_companiesPitchEvents.REQUEST)
    );
    fetch(`${GET_COMPANIES_FOR_PITCH_EVENTS_API}/${eventId}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_companiesPitchEvents.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_companiesPitchEvents.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_companiesPitchEvents.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.get_companiesPitchEvents.FAILURE)
        );
        console.log("error get_companiesPitchEvents ..", error);
      });
  };
};
export const GetAllInvestorForEventsAction = (eventId) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(
      actionCreator(PitchDayActionTypes.get_investorPitchEvents.REQUEST)
    );
    fetch(`${GET_INVESTOR_FOR_PITCH_EVENTS_API}/${eventId}`, {
      method: "GET",
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_investorPitchEvents.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_investorPitchEvents.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.get_investorPitchEvents.SUCCESS,
              response
            )
          );
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.get_investorPitchEvents.FAILURE)
        );
        console.log("error get_investorPitchEvents ..", error);
      });
  };
};

export const PutUpdateCompniesForPitchEventsAction = (
  postData,
  pitchEventId
) => {
  return (dispatch, getState) => {
    let token = getState().loginReducer.loggedInUserDetails.data.token;
    dispatch(
      actionCreator(PitchDayActionTypes.put_companiesToPitchEvents.REQUEST)
    );
    fetch(`${UPDATE_COMPANIES_FOR_PITCH_EVENTS_API}/${pitchEventId}`, {
      method: "PUT",
      body: JSON.stringify(postData),
      headers: jsonApiHeader(token),
    })
      .then(checkHttpStatus)
      .then(function (response) {
        if (response.status.toUpperCase() === "FAILURE") {
          dispatch(
            actionCreator(
              PitchDayActionTypes.put_companiesToPitchEvents.FAILURE,
              response
            )
          );
          showErrorMessage(response.message);
        } else if (response.status.toUpperCase() === "EXPIRED") {
          showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
          localStorage.clear();
          dispatch(
            actionCreator(
              PitchDayActionTypes.put_companiesToPitchEvents.FAILURE,
              response
            )
          );
          window.location.reload();
        } else {
          dispatch(
            actionCreator(
              PitchDayActionTypes.put_companiesToPitchEvents.SUCCESS,
              response
            )
          );
          showSuccessMessage("Details Updated Successfully");
          dispatch(GetAllPitchEventsAction());
        }
      })
      .catch(function (error) {
        dispatch(
          actionCreator(PitchDayActionTypes.put_companiesToPitchEvents.FAILURE)
        );
        console.log("error put_companiesToPitchEvents ..", error);
      });
  };
};
