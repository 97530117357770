import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import aws from 'aws-sdk/clients/s3';
import uuidv1 from 'uuid/v1';
import { config } from 'aws-sdk/lib/core';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { PutFounderUpdateAction, PostCreateFounderAction, ResetErrorDataAction } from '../actions/FoundersActions';
import { renderTextField, renderFileUpload } from "../../../../shared/reduxFields";
import { showErrorMessage, imageRegExp } from '../../../../shared/utility';
import validate from './FoundersEditModalValidate';



class AddEditFounderModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingDoc: false,
            isEnableUpload: false,
            isEnableSave: false,
            selectedDoc: '',
        }
    }

    componentDidMount = () => {
        if(this.props.type === "edit") {
            let founderDetails = this.props.founderDetails;
            this.props.dispatch(change('AddEditFounderModalForm', 'firstName', founderDetails.firstName));
            this.props.dispatch(change('AddEditFounderModalForm', 'lastName', founderDetails.lastName));
            this.props.dispatch(change('AddEditFounderModalForm', 'email', founderDetails.email));
            this.props.dispatch(change('AddEditFounderModalForm', 'designation', founderDetails.designation));
            this.props.dispatch(change('AddEditFounderModalForm', 'uploadDoc_S3', founderDetails.image));
            this.props.dispatch(change('AddEditFounderModalForm', 'linkedin', founderDetails.linkedln));
            this.props.dispatch(change('AddEditFounderModalForm', 'twitter', founderDetails.twitter));
            this.props.dispatch(change('AddEditFounderModalForm', 'shortBio', founderDetails.shortBio));
        }
    }

    onChangeHandler = (event) => {
        let file = event.target.files[0];
        if (file !== undefined) {
            if (!file) {
                this.props.ResetErrorDataAction();
            }
            if (imageRegExp.exec(file.name)) {
                this.setState({
                    selectedDoc: file,
                    isEnableUpload: true
                }, () => {
                    this.props.ResetErrorDataAction();
                    this.props.dispatch(change('AddEditFounderModalForm', 'uploadDoc_S3', null));

                })
            } else {
                showErrorMessage("File type not supported");
                this.setState({
                    selectedDoc: '',
                    isEnableUpload: false
                }, () => {
                    this.props.dispatch(change('AddEditFounderModalForm', 'uploadDoc_S3', null));
                })
            }
        }
    }

    onClickFileHandler = () => {
        this.setState({
            loadingDoc: false,
            isEnableUpload: false,
            isEnableSave: false,
            selectedDoc: '',
        }, () => {
            this.props.dispatch(change('AddEditFounderModalForm', 'uploadDoc_S3', null));
            return document.getElementById('uploadDoc_S3').value = null;
        })
    }

    onUploadClickHandler = () => {
        config.update({
            region: process.env.REACT_APP_S3_REGION_CONTENT,
            accessKeyId: process.env.REACT_APP_S3_ACCESSKEY_CONTENT,
            secretAccessKey: process.env.REACT_APP_S3_ACCESSSECRET_CONTENT
        });

        const s3bucket = new aws();
        if (this.state.isEnableUpload) {
            this.setState({
                loadingDoc: true
            }, () => {
                const params = {
                    Key: uuidv1(),
                    ContentType: `Image/${imageRegExp.exec(this.state.selectedDoc.name)[1]}`,
                    Body: this.state.selectedDoc,
                    Bucket: process.env.REACT_APP_S3_BUCKET_CONTENT,
                    ACL: 'public-read',
                }
                s3bucket.upload(params, (err, data) => {
                    if (data && !err) {
                        this.setState({
                            loadingDoc: false,
                            isEnableUpload: false,
                            isEnableSave: true,
                            selectedDoc: '',
                        }, () => {
                            this.props.dispatch(change('AddEditFounderModalForm', 'uploadDoc_S3', data.Location))
                        })
                    } else {
                        this.setState({
                            loadingDoc: false,
                            isEnableUpload: false,
                            isEnableSave: false,
                            selectedDoc: '',
                        })
                    }
                })
            })
        }
    }

    onFormSubmitHandler = (formData) => {
        const founderDetails = formData
        const postData = {
            "firstName": founderDetails.firstName,
            "lastName": founderDetails.lastName,
            "email": formData.email,
            "designation": formData.designation,
            "image": founderDetails.uploadDoc_S3,
            "linkedln": founderDetails.linkedin,
            "twitter": founderDetails.twitter,
            "shortBio": founderDetails.shortBio,
        }
        if(this.props.type === "add") {
            this.props.PostCreateFounderAction(postData, this.state.type);
        } else {
            this.props.PutFounderUpdateAction(postData, this.props.founderId);
        }
        this.props.handleClose();
    }

    render() {
        const { classes, handleSubmit, type, founderDetails } = this.props;
        const { isEnableUpload, isEnableSave, loadingDoc } = this.state;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>{type === "add" ? "Add" : "Edit"} Founder Details</h6>
                                </MuiDialogTitle>
                                <Divider />
                                <MuiDialogContent>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Field
                                                id="firstName"
                                                name="firstName"
                                                label="Enter First Name"
                                                required={true}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="lastName"
                                                name="lastName"
                                                label="Enter Last Name"
                                                required={true}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="email"
                                                name="email"
                                                label="Enter Email"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="designation"
                                                name="designation"
                                                label="Enter Designation"
                                                required={true}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem md={9} xs={12}>
                                            <Field
                                                id="uploadExcel"
                                                name="uploadExcel"
                                                label="Select Image"
                                                required={(founderDetails.image === null || founderDetails.image === '')? true : false}
                                                onClick={() => this.onClickFileHandler()}
                                                onChange={(e) => this.onChangeHandler(e)}
                                                component={renderFileUpload}
                                            />
                                        </GridItem>
                                        <GridItem md={3} xs={12}>
                                            <Button
                                                disabled={!isEnableUpload || loadingDoc}
                                                color="rose"
                                                onClick={() => this.onUploadClickHandler()}
                                            >
                                                {!isEnableSave ? "Upload" : "Uploaded"}
                                                {loadingDoc && <CircularProgress size={25} />}
                                            </Button>
                                        </GridItem>
                                        <GridItem md={12} xs={12} hidden>
                                            <Field
                                                id="uploadDoc_S3"
                                                name="uploadDoc_S3"
                                                disabled="true"
                                                required="true"
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="linkedin"
                                                name="linkedin"
                                                label="LinkedIn"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="twitter"
                                                name="twitter"
                                                label="Twitter"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Field
                                                id="shortBio"
                                                name="shortBio"
                                                label="Short Bio"
                                                required={false}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple type="submit" color="rose">
                                        SUBMIT
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

AddEditFounderModal = reduxForm({
    form: "AddEditFounderModalForm",
    validate,
    enableReinitialize: true
})(AddEditFounderModal);


function mapStateToProps(state) {
    return {
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PutFounderUpdateAction,
            ResetErrorDataAction,
            PostCreateFounderAction
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AddEditFounderModal));
