import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
// import { Delete } from '@material-ui/icons';
// import BlockIcon from '@material-ui/icons/Block';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import { GetInvestorTypeListAction } from './actions/InvestorTypeActions';
import AddEditInvestorTypeModal from './Modal/InvestorTypeAddEditModal';
// import DeleteInvestorTypeModal from './Modal/DeleteInvestorTypeModal';


class InvestorTypeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            investorTypeId: 0,
            openAddAddEditInvestorTypeModal: false,
            investorTypeDetails: '',
            openDeleteInvestorTypeModal: false,
            type: ''
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.props.GetInvestorTypeListAction();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.investorTypeList !== undefined && this.props.investorTypeList !== null && this.props.investorTypeList !== prevProps.investorTypeList) {
            this.setState({
                data: this.props.investorTypeList
            })
        }
    }

    handleEditInvestorTypeDetails = (event, rowData) => {
        let investorTypeId = rowData.id;
        this.setState({
            investorTypeId: investorTypeId,
            investorTypeDetails: rowData,
            openAddAddEditInvestorTypeModal: true,
            type: "edit"
        })
    }

    handleDeleteInvestorType = (event, rowData) => {
        let investorTypeId = rowData.id;
        this.setState({
            investorTypeId: investorTypeId,
            investorTypeDetails: rowData,
            openDeleteInvestorTypeModal: true
        })
    }

    handleDeactivateInvestorType = (event, rowData) => {
        let investorTypeId = rowData.id;
        this.setState({
            investorTypeId: investorTypeId,
            investorTypeDetails: rowData,
        })
    }

    handleClose = () => {
        this.setState({
            openAddAddEditInvestorTypeModal: false,
            openDeleteInvestorTypeModal: false,
            investorTypeId: 0,
            investorTypeDetails: ''
        })
    }

    addInvestorTypeClickHandler = () => {
        this.setState({
            openAddAddEditInvestorTypeModal: true,
            type: 'add'
        })
    }

    render() {
        const { loading } = this.props;
        const { 
            data, openAddAddEditInvestorTypeModal, openDeleteInvestorTypeModal, 
            investorTypeId, investorTypeDetails, type
        } = this.state;

        return (
            <>
                {openAddAddEditInvestorTypeModal && <AddEditInvestorTypeModal investorTypeId={investorTypeId} investorTypeDetails={investorTypeDetails} type={type} handleClose={() => this.handleClose()}/>}
                {/* {openDeleteInvestorTypeModal && <DeleteInvestorTypeModal investorTypeId={investorTypeId} investorTypeDetails={investorTypeDetails} handleClose={() => this.handleClose()}/>} */}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Investor Type List</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable 
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { 
                                            title: 'Type', 
                                            field: 'name',                                 
                                        },
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        sorting: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                        exportButton: true,
                                        pageSize: 200,
                                        pageSizeOptions: [100, 200, 300],
                                    }}
                                    isLoading={loading}
                                    actions={
                                        [
                                            {
                                                icon: Refresh,
                                                tooltip: 'Refresh List',
                                                isFreeAction: true,
                                                onClick: () => this.loadData()
                                            },
                                            {
                                                icon: 'add',
                                                tooltip: 'Create Investor Type',
                                                isFreeAction: true,
                                                onClick: () => this.addInvestorTypeClickHandler()
                                            },
                                            rowData => ({
                                                icon: Edit,
                                                tooltip: 'Update Investor Type Details',
                                                disabled: rowData.isactive == "N" ? true : false,
                                                onClick: (event, rowData) => this.handleEditInvestorTypeDetails(event, rowData)
                                            }),
                                            // rowData => ({
                                            //     icon: Delete,
                                            //     tooltip: 'Delete Investor type',
                                            //     disabled: rowData.isActive === true ? true : false,
                                            //     onClick: (event, rowData) => this.handleDeleteInvestorType(event, rowData)
                                            // })
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

InvestorTypeList = reduxForm({
    form: 'InvestorTypeListForm',
    enableReinitialize: true
})(InvestorTypeList)

function mapStateToProps(state) {
    return {
        loading: state.investorTypeReducer.loading,
        investorTypeList: state.investorTypeReducer.investorTypeList !== null && state.investorTypeReducer.investorTypeList.data,
        loggedInUserDetails: state.investorTypeReducer.loggedInUserDetails !== null && state.investorTypeReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            GetInvestorTypeListAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(InvestorTypeList))
