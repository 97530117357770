import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';

import { Refresh } from '@material-ui/icons';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import { GetCompaniesLikedByInvestorListAction } from './actions/InvestorsActions';

class CompaniesLikedByInvestorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.props.GetCompaniesLikedByInvestorListAction();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.companiesLikedByInvestorList !== undefined && this.props.companiesLikedByInvestorList !== null && this.props.companiesLikedByInvestorList !== prevProps.companiesLikedByInvestorList) {
            this.setState({
                data: this.props.companiesLikedByInvestorList
            })
        }
    }

    getAllCompanies = (rowData) => {
        let list = '';
        rowData.companies.forEach((item) => {
          list += `${item.name}, `;
        });
        return list.slice(0, -2);
    }

   

    render() {
        const { loading } = this.props;
        const { data } = this.state;

        return (
            <>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader>
                                <CardIcon color='rose' icon>
                                    <ViewListIcon />
                                </CardIcon>
                                <h4>Investors List</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable 
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    title=""
                                    columns={[
                                        { 
                                            title: 'Investor', 
                                            field: 'investor',                                 
                                        },
                                        { 
                                            title: 'Companies', 
                                            field: 'companies',        
                                            render: (rowData) => this.getAllCompanies(rowData)                       
                                        }
                                    ]}
                                    data={data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        sorting: true,
                                        headerStyle: {
                                            backgroundColor: "#ccc",
                                            color: '#000',
                                            fontWeight: 600
                                        },
                                        rowStyle: {
                                            backgroundColor: '#EEE',
                                        },
                                        exportButton: true,
                                        pageSize: 200,
                                        pageSizeOptions: [100, 200, 300],
                                    }}
                                    isLoading={loading}
                                    actions={
                                        [
                                            {
                                                icon: Refresh,
                                                tooltip: 'Refresh List',
                                                isFreeAction: true,
                                                onClick: () => this.loadData()
                                            },
                                        ]
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        )
    }
}

CompaniesLikedByInvestorList = reduxForm({
    form: 'CompaniesLikedByInvestorListForm',
    enableReinitialize: true
})(CompaniesLikedByInvestorList)

function mapStateToProps(state) {
    return {
        loading: state.investorReducer.loading,
        companiesLikedByInvestorList: state.investorReducer.companiesLikedByInvestor !== null && state.investorReducer.companiesLikedByInvestor.data,
        loggedInUserDetails: state.investorReducer.loggedInUserDetails !== null && state.investorReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            GetCompaniesLikedByInvestorListAction,
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CompaniesLikedByInvestorList))
