/**
 @desc
 * Centralized unique action types for UAM.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const GET_ALL_USER_API = `${API_URL}/admins/get`;
export const POST_CREATE_USER_API = `${API_URL}/admins`;
export const POST_UPDATE_USER_API = `${API_URL}/admins/update`;
export const POST_BLOCK_UNBLOCK_USER_BY_ID_API = `${API_URL}/admins/delete`;

export const GET_ALL_ROLES_API = `${API_URL}/roles/get`;

export const UamActionTypes = {
    get_AllUsers: createRequestActionTypes('GET_ALL_USER'),
    post_CreateUser: createRequestActionTypes('POST_CREATE_USER'),
    post_UpdateUser: createRequestActionTypes('POST_UPDATE_USER'),
    post_BlockUnblockUser: createRequestActionTypes('POST_BLOCK_UNBLOCK_USER_BY_ID'),
    get_AllRoles: createRequestActionTypes('GET_ALL_ROLES'),
};