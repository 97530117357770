/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_DOMAINS_API = `${API_URL}/domains/add`;
export const GET_DOMAINS_LIST_API = `${API_URL}/domains/get`;
export const PUT_UPDATE_DOMAINS_API = `${API_URL}/domains/update`;
export const DELETE_DOMAINS_API = `${API_URL}/domains/delete`;

export const DomainsActionTypes = {
    post_createDomains: createRequestActionTypes('POST_CREATE_DOMAINS'),
    get_domainsList: createRequestActionTypes('GET_DOMAINS_LIST'),
    put_updateDomainsDetail: createRequestActionTypes('UPDATE_DOMAINS_DETAIL'),
    delete_domains: createRequestActionTypes('DELETE_DOMAINS'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};