import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from "redux-form";

import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import ViewListIcon from '@material-ui/icons/ViewList';
import Button from "components/CustomButtons/Button.js";
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { Paper } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import styles from '../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { GetAllCompaniesForEventsAction, GetAllPitchEventsAction } from './actions/PitchDayActions';
import { renderSelectField } from '../../../shared/reduxFields';


class CompaniesByEventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            eventsData: [],
            pitchEventId: null,
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        this.props.GetAllPitchEventsAction();
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.allPitchEvents !== undefined && this.props.allPitchEvents !== null && this.props.allPitchEvents !== prevProps.allPitchEvents) {
            this.setState({
                eventsData: this.props.allPitchEvents
            })
        }
        if(this.props.companiesPitchEvents !== undefined && this.props.companiesPitchEvents !== null && this.props.companiesPitchEvents !== prevProps.companiesPitchEvents) {
            this.setState({
                data: [...this.state.data, this.props.companiesPitchEvents]
            })
        }
    }

    findAllCompanies = (rowData) => {
        let list = '';
            rowData.companies.forEach((item) => {
              list += `${item.name}, `;
            });
            return list.slice(0, -2);
    }

    onFormSubmitHandler = (data) => {
        this.props.GetAllCompaniesForEventsAction(data.selectEvent);
    }


    render() {
        const { loading, handleSubmit } = this.props;
        const { data, eventsData } = this.state;
        return (
          <>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader>
                    <CardIcon color="rose" icon>
                      <ViewListIcon />
                    </CardIcon>
                    <h4>Get Companies By Pitch Event</h4>
                  </CardHeader>
                  <CardBody>
                    <form
                      onSubmit={handleSubmit((val) =>
                        this.onFormSubmitHandler(val)
                      )}
                    >
                      <GridContainer>
                        <GridItem xs={12} md={6}>
                          <Field
                            id="selectEvent"
                            name="selectEvent"
                            label="Select Event"
                            required={true}
                            component={renderSelectField}
                          >
                            {eventsData.length > 0 &&
                              eventsData.map((obj, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={obj.pitchEventId}
                                  >
                                    {obj.name}
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </GridItem>
                        <Button type="submit" color="rose">
                          SUBMIT
                        </Button>
                      </GridContainer>
                    </form>
                      <MaterialTable
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                        }}
                        title=""
                        columns={[
                          {
                            title: "Companies",
                            field: "companies",
                            filtering: false,
                            render: (rowData) => this.findAllCompanies(rowData),
                          },
                        ]}
                        data={data}
                        options={{
                          actionsColumnIndex: -1,
                          filtering: true,
                          sorting: true,
                          headerStyle: {
                            backgroundColor: "#ccc",
                            color: "#000",
                            fontWeight: 600,
                          },
                          rowStyle: {
                            backgroundColor: "#EEE",
                          },
                          exportButton: true,
                          pageSize: 200,
                          pageSizeOptions: [100, 200, 300],
                        }}
                        isLoading={loading}
                      />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </>
        );
    }
}

CompaniesByEventList = reduxForm({
    form: 'CompaniesByEventListForm',
    enableReinitialize: true
})(CompaniesByEventList)

function mapStateToProps(state) {
    return {
        loading: state.pitchDayReducer.loading,
        companiesPitchEvents: state.pitchDayReducer.companiesPitchEvents !== null && state.pitchDayReducer.companiesPitchEvents.data,
        allPitchEvents: state.pitchDayReducer.allPitchEvents !== null && state.pitchDayReducer.allPitchEvents.data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            GetAllCompaniesForEventsAction,
            GetAllPitchEventsAction
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CompaniesByEventList))
