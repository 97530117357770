import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

import { Refresh } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import {
  GetAllPitchEventsAction,
  PatchUpdatePitchEventsAction,
} from "./actions/PitchDayActions";
import { GetCompanyListAction } from "../Company/actions/CompanyActions";
import AddEditPitchEventModal from "./Modal/AddEditPitchEventModal";
import ChangeVideoStatusModal from "./Modal/ChangeVideoStatusModal";
import AddEditCompanyModal from "./Modal/AddEditCompanyModal";
import AddEditVideoModal from "./Modal/AddEditVideoModal";
import AddEditLocationModal from "./Modal/AddEditLocationModal";

class PitchEventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      companyData: [],
      pitchEventId: 0,
      opeAddEditPitchEventModal: false,
      pitchEventDetails: "",
      type: "",
      openVideoStatusModal: false,
      postData: null,
      openCompanyModal: false,
      openVideoModal: false,
      openLocationModal: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetAllPitchEventsAction();
    this.props.GetCompanyListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.allPitchEvents !== undefined &&
      this.props.allPitchEvents !== null &&
      this.props.allPitchEvents !== prevProps.allPitchEvents
    ) {
      this.setState({
        data: this.props.allPitchEvents,
      });
    }
    if (
      this.props.companyList !== undefined &&
      this.props.companyList !== null &&
      this.props.companyList !== prevProps.companyList
    ) {
      this.setState({
        companyData: this.props.companyList,
      });
    }
  };

  handleEditPitchEventDetails = (event, rowData) => {
    let pitchEventId = rowData.id;
    this.setState({
      pitchEventId: pitchEventId,
      pitchEventDetails: rowData,
      opeAddEditPitchEventModal: true,
      type: "edit",
    });
  };

  // handleDeactivatePitchEvent = (event, rowData) => {
  //     let pitchEventId = rowData.id;
  //     this.setState({
  //         pitchEventId: pitchEventId,
  //         pitchEventDetails: rowData,
  //     })
  // }

  handleClose = () => {
    this.setState({
      opeAddEditPitchEventModal: false,
      pitchEventId: 0,
      pitchEventDetails: "",
      openVideoStatusModal: false,
      postData: null,
      openCompanyModal: false,
      openVideoModal: false,
      openLocationModal: false,
    });
  };

  addPitchEventClickHandler = () => {
    this.setState({
      opeAddEditPitchEventModal: true,
      type: "add",
    });
  };

  getDates = (rowData) => {
    return rowData.pitchDayDetails
      ?.map((item) => moment(item.date).format("DD/MM/YYYY"))
      ?.join(", ");
  };

  getTime = (rowData) => {
    return rowData.pitchDayDetails
      ?.map((item) => moment(item.time).format("hh:mm a"))
      ?.join(", ");
  };

  handleLoginWindowStatus = (e, rowData, type) => {
    let obj = {
      pitchDayId: rowData.id,
    };
    if (type === "PD") {
      obj["pitchDayLoginWindow"] = !rowData.pitchDayLoginWindow;
    } else if (type === "SD") {
      obj["scaleUpLoginWindow"] = !rowData.scaleUpLoginWindow;
    }
    this.props.PatchUpdatePitchEventsAction(obj, rowData.pitchEventId);
  };

  handlepitchDayWindowStatus = (e, rowData, type) => {
    let obj = {
      pitchDayId: rowData.id,
    };
    if (type === "PD") {
      obj["pitchDayWindow"] = !rowData.pitchDayWindow;
    } else if (type === "SD") {
      obj["scaleUpWindow"] = !rowData.scaleUpWindow;
    } else if (type === "PDOW") {
      obj["pitchDayOfflineWindow"] = !rowData.pitchDayOfflineWindow;
    } else if (type === "SUOW") {
      obj["scaleUpOfflineWindow"] = !rowData.scaleUpOfflineWindow;
    }
    this.props.PatchUpdatePitchEventsAction(obj, rowData.pitchEventId);
  };

  pitchVideoHandler = (rowData, type) => {
    let postData = {
      pitchDayId: rowData.id,
    };
    if (type === "PD") {
      postData["pitchVideoActive"] = !rowData.pitchVideoActive;
    } else if (type === "SD") {
      postData["scaleUpVideoActive"] = !rowData.scaleUpVideoActive;
    }
    this.setState({
      postData: postData,
      openVideoStatusModal: true,
      pitchEventId: rowData.pitchEventId,
    });
  };

  addCompanyHandler = (rowData) => {
    this.setState({
      openCompanyModal: true,
      pitchEventId: rowData.pitchEventId,
      pitchEventDetails: rowData,
    });
  };

  addVideoHandler = (rowData) => {
    this.setState({
      openVideoModal: true,
      pitchEventId: rowData.pitchEventId,
      pitchEventDetails: rowData,
    });
  };

  addLocationHandler = (rowData) => {
    this.setState({
      openLocationModal: true,
      pitchEventId: rowData.pitchEventId,
      pitchEventDetails: rowData,
    });
  };

  compare = (a, b) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    return 0;
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      opeAddEditPitchEventModal,
      openVideoStatusModal,
      pitchEventId,
      pitchEventDetails,
      type,
      postData,
      openCompanyModal,
      companyData,
      openVideoModal,
      openLocationModal,
    } = this.state;
    return (
      <>
        {opeAddEditPitchEventModal && (
          <AddEditPitchEventModal
            pitchEventId={pitchEventId}
            pitchEventDetails={pitchEventDetails}
            type={type}
            handleClose={() => this.handleClose()}
          />
        )}
        {openCompanyModal && (
          <AddEditCompanyModal
            pitchEventId={pitchEventId}
            pitchEventDetails={pitchEventDetails}
            type={type}
            companyData={companyData}
            handleClose={() => this.handleClose()}
          />
        )}
        {openVideoModal && (
          <AddEditVideoModal
            pitchEventId={pitchEventId}
            pitchEventDetails={pitchEventDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        {openLocationModal && (
          <AddEditLocationModal
            pitchEventId={pitchEventId}
            pitchEventDetails={pitchEventDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        {openVideoStatusModal && (
          <ChangeVideoStatusModal
            postData={postData}
            pitchEventId={pitchEventId}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Pitch Event List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Pitch Event Name",
                      field: "name",
                    },
                    {
                      title: "Date",
                      field: "date",
                      render: (rowData) => this.getDates(rowData),
                    },
                    {
                      title: "Time",
                      field: "time",
                      render: (rowData) => this.getTime(rowData),
                    },
                    {
                      title: "Pitch Day Video",
                      field: "pitchVideoActive",
                      filtering: false,
                      render: (rowData) =>
                        rowData.pitchVideoActive === true ? (
                          <Button
                            color="success"
                            onClick={() =>
                              this.pitchVideoHandler(rowData, "PD")
                            }
                          >
                            Active
                          </Button>
                        ) : (
                          <Button
                            color="rose"
                            onClick={() =>
                              this.pitchVideoHandler(rowData, "PD")
                            }
                          >
                            Inactive
                          </Button>
                        ),
                    },
                    {
                      title: "Scale Up Video",
                      field: "scaleUpVideoActive",
                      filtering: false,
                      render: (rowData) =>
                        rowData.scaleUpVideoActive === true ? (
                          <Button
                            color="success"
                            onClick={() =>
                              this.pitchVideoHandler(rowData, "SD")
                            }
                          >
                            Active
                          </Button>
                        ) : (
                          <Button
                            color="rose"
                            onClick={() =>
                              this.pitchVideoHandler(rowData, "SD")
                            }
                          >
                            Inactive
                          </Button>
                        ),
                    },
                    {
                      title: "Companies",
                      field: "companies",
                      filtering: false,
                      render: (rowData) => (
                        <Button
                          color={
                            rowData.pitchDayCompanies.length > 0
                              ? "success"
                              : "rose"
                          }
                          onClick={() => this.addCompanyHandler(rowData)}
                        >
                          Add Companies
                        </Button>
                      ),
                    },
                    {
                      title: "Video",
                      filtering: false,
                      render: (rowData) => (
                        <Button
                          color={
                            rowData.pitchDayVideo !== null ? "success" : "info"
                          }
                          onClick={() => this.addVideoHandler(rowData)}
                        >
                          Add Pitch Day Video
                        </Button>
                      ),
                    },
                    {
                      title: "Location",
                      filtering: false,
                      render: (rowData) => (
                        <Button
                          color={
                            rowData.pitchDayLocation !== null
                              ? "success"
                              : "info"
                          }
                          onClick={() => this.addLocationHandler(rowData)}
                        >
                          Add Pitch Day Location
                        </Button>
                      ),
                    },
                    {
                      title: "Pitch Day Window",
                      field: "pitchDayWindow",
                      render: (rowData) =>
                        rowData.pitchDayWindow === true ? (
                          <span style={{ color: "green" }}> Active </span>
                        ) : (
                          <span style={{ color: "red" }}> Inactive </span>
                        ),
                    },
                    {
                      title: "Pitch Day Offline Window",
                      field: "pitchDayOfflineWindow",
                      render: (rowData) =>
                        rowData.pitchDayOfflineWindow === true ? (
                          <span style={{ color: "green" }}> Active </span>
                        ) : (
                          <span style={{ color: "red" }}> Inactive </span>
                        ),
                    },
                    {
                      title: "Scale Up Window",
                      field: "scaleUpWindow",
                      render: (rowData) =>
                        rowData.scaleUpWindow === true ? (
                          <span style={{ color: "green" }}> Active </span>
                        ) : (
                          <span style={{ color: "red" }}> Inactive </span>
                        ),
                    },
                    {
                      title: "Scale Up Offline Window",
                      field: "scaleUpOfflineWindow",
                      render: (rowData) =>
                        rowData.scaleUpOfflineWindow === true ? (
                          <span style={{ color: "green" }}> Active </span>
                        ) : (
                          <span style={{ color: "red" }}> Inactive </span>
                        ),
                    },
                    {
                      title: "Pitch Day Login Window",
                      field: "pitchDayLoginWindow",
                      render: (rowData) =>
                        rowData.pitchDayLoginWindow === true ? (
                          <span style={{ color: "green" }}> Active </span>
                        ) : (
                          <span style={{ color: "red" }}> Inactive </span>
                        ),
                    },
                    {
                      title: "Scaleup Login Window",
                      field: "scaleUpLoginWindow",
                      render: (rowData) =>
                        rowData.scaleUpLoginWindow === true ? (
                          <span style={{ color: "green" }}> Active </span>
                        ) : (
                          <span style={{ color: "red" }}> Inactive </span>
                        ),
                    },
                  ]}
                  data={data.sort((a, b) => this.compare(a, b))}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  isLoading={loading}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Create Pitch Event",
                      isFreeAction: true,
                      onClick: () => this.addPitchEventClickHandler(),
                    },
                    (rowData) => ({
                      icon:
                        rowData.pitchDayWindow === true
                          ? CancelIcon
                          : CheckCircleOutlineIcon,
                      iconProps: {
                        color:
                          rowData.pitchDayWindow === true ? "error" : "primary",
                      },
                      tooltip:
                        rowData.pitchDayWindow === true
                          ? "Disapprove Pitch Day Window"
                          : "Approve Pitch Day Window",
                      onClick: (event, rowData) =>
                        this.handlepitchDayWindowStatus(event, rowData, "PD"),
                    }),
                    (rowData) => ({
                      icon:
                        rowData.scaleUpWindow === true
                          ? CancelIcon
                          : CheckCircleOutlineIcon,
                      iconProps: {
                        color:
                          rowData.scaleUpWindow === true ? "error" : "primary",
                      },
                      tooltip:
                        rowData.scaleUpWindow === true
                          ? "Disapprove Scale Up Day Window"
                          : "Approve Scale Up Day Window",
                      onClick: (event, rowData) =>
                        this.handlepitchDayWindowStatus(event, rowData, "SD"),
                    }),
                    (rowData) => ({
                      icon:
                        rowData.pitchDayOfflineWindow === true
                          ? CancelIcon
                          : CheckCircleOutlineIcon,
                      iconProps: {
                        color:
                          rowData.pitchDayOfflineWindow === true
                            ? "error"
                            : "primary",
                      },
                      tooltip:
                        rowData.pitchDayOfflineWindow === true
                          ? "Disapprove Pitch Day Offline Window"
                          : "Approve Pitch Day Offline Window",
                      onClick: (event, rowData) =>
                        this.handlepitchDayWindowStatus(event, rowData, "PDOW"),
                    }),
                    (rowData) => ({
                      icon:
                        rowData.scaleUpOfflineWindow === true
                          ? CancelIcon
                          : CheckCircleOutlineIcon,
                      iconProps: {
                        color:
                          rowData.scaleUpOfflineWindow === true
                            ? "error"
                            : "primary",
                      },
                      tooltip:
                        rowData.scaleUpOfflineWindow === true
                          ? "Disapprove Scale Up Offline Window"
                          : "Approve Scale Up Offline Window",
                      onClick: (event, rowData) =>
                        this.handlepitchDayWindowStatus(event, rowData, "SUOW"),
                    }),
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Update Pitch Event Details",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditPitchEventDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon:
                        rowData.pitchDayLoginWindow === true
                          ? CancelIcon
                          : CheckCircleOutlineIcon,
                      tooltip:
                        rowData.pitchDayLoginWindow === true
                          ? "Disapprove Login Window"
                          : "Approve Login Window",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleLoginWindowStatus(event, rowData, "PD"),
                    }),
                    (rowData) => ({
                      icon:
                        rowData.scaleUpLoginWindow === true
                          ? CancelIcon
                          : CheckCircleOutlineIcon,
                      tooltip:
                        rowData.scaleUpLoginWindow === true
                          ? "Disapprove Sclae Up Login Window"
                          : "Approve Scale Up Login Window",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleLoginWindowStatus(event, rowData, "SD"),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

PitchEventList = reduxForm({
  form: "PitchEventListForm",
  enableReinitialize: true,
})(PitchEventList);

function mapStateToProps(state) {
  return {
    loading: state.pitchDayReducer.loading,
    allPitchEvents:
      state.pitchDayReducer.allPitchEvents !== null &&
      state.pitchDayReducer.allPitchEvents.data,
    companyList:
      state.companyReducer.companyList !== null &&
      state.companyReducer.companyList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetAllPitchEventsAction,
        PatchUpdatePitchEventsAction,
        GetCompanyListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PitchEventList));
