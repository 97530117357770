import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { GetSectorListAction } from "./actions/SectorActions";
import AddEditSectorModal from "./Modal/SectorAddEditModal";
import DeleteSectorModal from "./Modal/DeleteSectorModal";

class SectorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sectorId: 0,
      openAddAddEditSectorModal: false,
      sectorDetails: "",
      openDeleteSectorModal: false,
      type: "",
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetSectorListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.sectorList !== undefined &&
      this.props.sectorList !== null &&
      this.props.sectorList !== prevProps.sectorList
    ) {
      this.setState({
        data: this.props.sectorList,
      });
    }
  };

  handleEditSectorDetails = (event, rowData) => {
    let sectorId = rowData.id;
    this.setState({
      sectorId: sectorId,
      sectorDetails: rowData,
      openAddAddEditSectorModal: true,
      type: "edit",
    });
  };

  handleDeleteSector = (event, rowData) => {
    let sectorId = rowData.id;
    this.setState({
      sectorId: sectorId,
      sectorDetails: rowData,
      openDeleteSectorModal: true,
    });
  };

  handleDeactivateSector = (event, rowData) => {
    let sectorId = rowData.id;
    this.setState({
      sectorId: sectorId,
      sectorDetails: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      openAddAddEditSectorModal: false,
      openDeleteSectorModal: false,
      sectorId: 0,
      sectorDetails: "",
    });
  };

  addSectorClickHandler = () => {
    this.setState({
      openAddAddEditSectorModal: true,
      type: "add",
    });
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      openAddAddEditSectorModal,
      openDeleteSectorModal,
      sectorId,
      sectorDetails,
      type,
    } = this.state;

    return (
      <>
        {openAddAddEditSectorModal && (
          <AddEditSectorModal
            sectorId={sectorId}
            sectorDetails={sectorDetails}
            type={type}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteSectorModal && (
          <DeleteSectorModal
            sectorId={sectorId}
            sectorDetails={sectorDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Sector List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Sector Name",
                      field: "name",
                    },
                    {
                      title: "Company Count",
                      field: "companyCount",
                      filtering: false,
                    },
                  ]}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  isLoading={loading}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Create Sector",
                      isFreeAction: true,
                      onClick: () => this.addSectorClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Update Sector Details",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditSectorDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Sector",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteSector(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

SectorList = reduxForm({
  form: "SectorListForm",
  enableReinitialize: true,
})(SectorList);

function mapStateToProps(state) {
  return {
    loading: state.sectorReducer.loading,
    sectorList:
      state.sectorReducer.sectorList !== null &&
      state.sectorReducer.sectorList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetSectorListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SectorList));
