import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, change } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Divider, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { PutSectorUpdateAction, PostCreateSectorAction, ResetErrorDataAction } from '../actions/SectorActions';
import { renderTextField } from "../../../../shared/reduxFields";
import validate from './SectorEditModalValidate';



class AddEditSectorModal extends Component {

    componentDidMount = () => {
        if(this.props.type === "edit") {
            let sectorDetails = this.props.sectorDetails;
            this.props.dispatch(change('AddEditSectorModalForm', 'name', sectorDetails.name));
        }
    }

    onFormSubmitHandler = (formData) => {
        const sectorDetails = formData
        const postData = {
            "name": sectorDetails.name,
        }
        if(this.props.type === "add") {
            this.props.PostCreateSectorAction(postData);
        } else {
            this.props.PutSectorUpdateAction(postData, this.props.sectorId);
        }
        this.props.handleClose();
    }

    render() {
        const { classes, handleSubmit, type } = this.props;

        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <Dialog onClose={() => this.props.handleClose()} aria-labelledby="customized-dialog-title" open={() => this.props.handleOpen()}>
                            <form onSubmit={handleSubmit(val => this.onFormSubmitHandler(val))}>
                                <MuiDialogTitle className={classes.modelTitle} id="customized-dialog-title" onClose={() => this.props.handleClose()}>
                                    <h6>{type === "add" ? "Add" : "Edit"} Sector Details</h6>
                                </MuiDialogTitle>
                                <Divider />
                                <MuiDialogContent>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Field
                                                id="name"
                                                name="name"
                                                label="Enter Sector Name"
                                                required={true}
                                                component={renderTextField}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button simple type="submit" color="rose">
                                        SUBMIT
                                    </Button>
                                    <Button link onClick={() => this.props.handleClose()}>
                                        CLOSE
                                    </Button>
                                </MuiDialogActions>
                            </form>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

AddEditSectorModal = reduxForm({
    form: "AddEditSectorModalForm",
    validate,
    enableReinitialize: true
})(AddEditSectorModal);


function mapStateToProps(state) {
    return {
        loggedInUserDetails: state.loginReducer.loggedInUserDetails !== null && state.loginReducer.loggedInUserDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({
            PutSectorUpdateAction,
            ResetErrorDataAction,
            PostCreateSectorAction
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AddEditSectorModal));
