import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { reduxForm } from "redux-form";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import { PutUpdateCompniesForPitchEventsAction } from '../actions/PitchDayActions';

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Loader from '../../../../components/Loader';

class AddEditCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompanyList: []
    };
  }

  componentDidMount = () => {
    this.setState({
        selectedCompanyList: this.props.pitchEventDetails.pitchDayCompanies
      });
  }


  companyChangeHandler = (e, value) => {
    this.setState({
        selectedCompanyList: value
    })
  }

  onFormSubmitHandler = () => {
    const companyData = this.state.selectedCompanyList;
    let selectedCompanyData = [];
    if(companyData.length > 0) {
        companyData.map((obj) => {
            selectedCompanyData.push(obj.id)
      })
    }
    let postData = {
        'companies': selectedCompanyData
    } 
    this.props.PutUpdateCompniesForPitchEventsAction(postData, this.props.pitchEventId);
    this.props.handleClose();
  };

  render() {
    const { loading, classes, handleSubmit, companyData } = this.props;
    const { selectedCompanyList } = this.state;
    return (
      <div className={classes.container}>
        {loading ? <Loader open={true} loaderMessage="Fetching..." /> :
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Dialog
              onClose={() => this.props.handleClose()}
              aria-labelledby="customized-dialog-title"
              open={() => this.props.handleOpen()}
            >
              <form
                onSubmit={handleSubmit((val) => this.onFormSubmitHandler(val))}
              >
                <MuiDialogTitle
                  className={classes.modelTitle}
                  id="customized-dialog-title"
                  onClose={() => this.props.handleClose()}
                >
                  <h6>Add Companies</h6>
                </MuiDialogTitle>
                <Divider />
                <MuiDialogContent>
                  <GridContainer>
                    <GridItem xs={12} md={12}>
                      <Autocomplete
                        multiple
                        id="multiple-company-select"
                        options={companyData}
                        getOptionLabel={(option) => option.name}
                        defaultValue={selectedCompanyList}
                        filterSelectedOptions={true}
                        onChange={(e, value) =>
                          this.companyChangeHandler(e, value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Select Companies"
                            placeholder="Select Companies"
                          />
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                </MuiDialogContent>
                <MuiDialogActions>
                  <Button type="submit" color="rose">
                    SUBMIT
                  </Button>
                  <Button onClick={() => this.props.handleClose()}>
                    CLOSE
                  </Button>
                </MuiDialogActions>
              </form>
            </Dialog>
          </GridItem>
        </GridContainer>
  }
      </div>
    );
  }
}

AddEditCompanyModal = reduxForm({
  form: "AddEditCompanyModalForm",
  enableReinitialize: true,
})(AddEditCompanyModal);

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        PutUpdateCompniesForPitchEventsAction
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddEditCompanyModal));
