import { StatusActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createStatus: null,
    statusList: null,
    UpdateStatus: null,
    deleteStatus: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case StatusActionTypes.post_createStatus.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case StatusActionTypes.post_createStatus.SUCCESS:
            return {
                ...state,
                loading: false,
                createStatus: payload,
            };
        case StatusActionTypes.post_createStatus.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case StatusActionTypes.get_statusList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case StatusActionTypes.get_statusList.SUCCESS:
            return {
                ...state,
                loading: false,
                statusList: payload,
            };
        case StatusActionTypes.get_statusList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case StatusActionTypes.delete_status.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case StatusActionTypes.delete_status.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteStatus: payload,
            };
        case StatusActionTypes.delete_status.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case StatusActionTypes.put_updateStatusDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case StatusActionTypes.put_updateStatusDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                UpdateStatus: payload,
            };
        case StatusActionTypes.put_updateStatusDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case StatusActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
