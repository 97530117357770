import { SectorActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createSector: null,
    sectorList: null,
    UpdateSector: null,
    deleteSector: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SectorActionTypes.post_createSector.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SectorActionTypes.post_createSector.SUCCESS:
            return {
                ...state,
                loading: false,
                createSector: payload,
            };
        case SectorActionTypes.post_createSector.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SectorActionTypes.get_sectorList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SectorActionTypes.get_sectorList.SUCCESS:
            return {
                ...state,
                loading: false,
                sectorList: payload,
            };
        case SectorActionTypes.get_sectorList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SectorActionTypes.delete_sector.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SectorActionTypes.delete_sector.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteSector: payload,
            };
        case SectorActionTypes.delete_sector.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SectorActionTypes.put_updateSectorDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SectorActionTypes.put_updateSectorDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                UpdateSector: payload,
            };
        case SectorActionTypes.put_updateSectorDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SectorActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
