/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_INVESTOR_TYPE_API = `${API_URL}/investorType/add`;
export const GET_INVESTOR_TYPE_LIST_API = `${API_URL}/investorType/get`;
export const PUT_UPDATE_INVESTOR_TYPE_API = `${API_URL}/investorType/update`;
export const DELETE_INVESTOR_TYPE_API = `${API_URL}/investorType/delete`;

export const InvestorTypeActionTypes = {
    post_createInvestorType: createRequestActionTypes('POST_CREATE_INVESTOR_TYPE'),
    get_investorTypeList: createRequestActionTypes('GET_INVESTOR_TYPE_LIST'),
    put_updateInvestorTypeDetail: createRequestActionTypes('UPDATE_INVESTOR_TYPE_DETAIL'),
    delete_investorType: createRequestActionTypes('DELETE_INVESTOR_TYPE'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};