import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { GetStatusListAction } from "./actions/StatusActions";
import AddEditStatusModal from "./Modal/StatusAddEditModal";
import DeleteStatusModal from "./Modal/DeleteStatusModal";

class StatusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      statusId: 0,
      openAddEditStatusModal: false,
      statusDetails: "",
      openDeleteStatusModal: false,
      type: "",
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetStatusListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.statusList !== undefined &&
      this.props.statusList !== null &&
      this.props.statusList !== prevProps.statusList
    ) {
      this.setState({
        data: this.props.statusList,
      });
    }
  };

  handleEditStatusDetails = (event, rowData) => {
    let statusId = rowData.id;
    this.setState({
      statusId: statusId,
      statusDetails: rowData,
      openAddEditStatusModal: true,
      type: "edit",
    });
  };

  handleDeleteStatus = (event, rowData) => {
    let statusId = rowData.id;
    this.setState({
      statusId: statusId,
      statusDetails: rowData,
      openDeleteStatusModal: true,
    });
  };

  handleDeactivateStatus = (event, rowData) => {
    let statusId = rowData.id;
    this.setState({
      statusId: statusId,
      statusDetails: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      openAddEditStatusModal: false,
      openDeleteStatusModal: false,
      statusId: 0,
      statusDetails: "",
    });
  };

  addStatusClickHandler = () => {
    this.setState({
      openAddEditStatusModal: true,
      type: "add",
    });
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      openAddEditStatusModal,
      openDeleteStatusModal,
      statusId,
      statusDetails,
      type,
    } = this.state;

    return (
      <>
        {openAddEditStatusModal && (
          <AddEditStatusModal
            statusId={statusId}
            statusDetails={statusDetails}
            type={type}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteStatusModal && (
          <DeleteStatusModal
            statusId={statusId}
            statusDetails={statusDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Status List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Status Name",
                      field: "name",
                    },
                  ]}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  isLoading={loading}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Create Status",
                      isFreeAction: true,
                      onClick: () => this.addStatusClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Update Status Details",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditStatusDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Status",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteStatus(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

StatusList = reduxForm({
  form: "StatusListForm",
  enableReinitialize: true,
})(StatusList);

function mapStateToProps(state) {
  return {
    loading: state.statusReducer.loading,
    statusList:
      state.statusReducer.statusList !== null &&
      state.statusReducer.statusList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetStatusListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StatusList));
