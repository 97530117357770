const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Sector Name Required';
    }
    if (!values.website) {
        errors.website = 'Website URL Required';
    }
    if (!values.uploadDoc_S3) {
        errors.uploadLogo = 'Image Required';
    }
    if (!values.email) {
        errors.email = 'Email Required';
    }
    if (!values.class) {
        errors.class = 'Class Required';
    }
    if (!values.status) {
        errors.status = 'Status Required';
    }
    if (values.womenLed === '') {
        errors.womenLed = 'Required';
    }
    return errors;
};
export default validate;