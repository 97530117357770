import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";

import ViewListIcon from "@material-ui/icons/ViewList";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { Paper } from "@material-ui/core";

import { Refresh } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { GetCompanyListAction } from "./actions/CompanyActions";
import AddEditCompanyModal from "./Modal/CompanyAddEditModal";
import DeleteCompanyModal from "./Modal/DeleteCompanyModal";
import ImageViewer from "../../../shared/ImageViewer";

class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      companyId: 0,
      openAddAddEditCompanyModal: false,
      companyDetails: "",
      openDeleteCompanyModal: false,
      type: "",
      openImageViewer: false,
      imgsArray: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    this.props.GetCompanyListAction();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.companyList !== undefined &&
      this.props.companyList !== null &&
      this.props.companyList !== prevProps.companyList
    ) {
      this.setState({
        data: this.props?.companyList,
      });
    }
  };

  ImageClickHandler = (rowData) => {
    let ImageArr = [];
    let ImgObj = {};
    ImgObj["src"] = rowData.logo;
    ImageArr.push(ImgObj);
    this.setState({
      openImageViewer: true,
      imgsArray: ImageArr,
    });
  };

  closeImageViewer = () => {
    this.setState({
      openImageViewer: false,
      imgsArray: [],
    });
  };

  handleEditCompanyDetails = (event, rowData) => {
    let companyId = rowData.id;
    this.setState({
      companyId: companyId,
      companyDetails: rowData,
      openAddAddEditCompanyModal: true,
      type: "edit",
    });
  };

  handleDeleteCompany = (event, rowData) => {
    let companyId = rowData.id;
    this.setState({
      companyId: companyId,
      companyDetails: rowData,
      openDeleteCompanyModal: true,
    });
  };

  handleDeactivateCompany = (event, rowData) => {
    let companyId = rowData.id;
    this.setState({
      companyId: companyId,
      companyDetails: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      openAddAddEditCompanyModal: false,
      openDeleteCompanyModal: false,
      companyId: 0,
      companyDetails: "",
    });
  };

  addCompanyClickHandler = () => {
    this.setState({
      openAddAddEditCompanyModal: true,
      type: "add",
    });
  };

  getAllSectors = (rowData) => {
    let list = "";
    rowData.sector.forEach((item) => {
      list += `${item}, `;
    });
    return list.slice(0, -2);
  };

  getAllFounders = (rowData) => {
    let list = "";
    rowData.founders.forEach((item) => {
      list += `${item.firstName} ${""} ${item.lastName}, `;
    });
    return list.slice(0, -2);
  };

  render() {
    const { loading, loggedInUserDetails } = this.props;
    const {
      data,
      openAddAddEditCompanyModal,
      openDeleteCompanyModal,
      companyId,
      companyDetails,
      type,
      openImageViewer,
      imgsArray,
    } = this.state;

    return (
      <>
        {openAddAddEditCompanyModal && (
          <AddEditCompanyModal
            companyId={companyId}
            companyDetails={companyDetails}
            type={type}
            handleClose={() => this.handleClose()}
          />
        )}
        {openDeleteCompanyModal && (
          <DeleteCompanyModal
            companyId={companyId}
            companyDetails={companyDetails}
            handleClose={() => this.handleClose()}
          />
        )}
        {openImageViewer && (
          <ImageViewer
            imgsArray={imgsArray}
            isOpen={openImageViewer}
            closeImgsViewer={() => this.closeImageViewer()}
          />
        )}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <CardIcon color="rose" icon>
                  <ViewListIcon />
                </CardIcon>
                <h4>Company List</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                  }}
                  title=""
                  columns={[
                    {
                      title: "Company Name",
                      field: "name",
                    },
                    {
                      title: "Stage of Funding",
                      field: "stageOfFunding",
                      render: (rowData) =>
                        rowData.stageOfFunding !== null &&
                        rowData.stageOfFunding !== ""
                          ? rowData.stageOfFunding
                          : "--",
                    },
                    {
                      title: "Logo",
                      field: "stageOfFunding",
                      filtering: false,
                      render: (rowData) => (
                        <img
                          src={rowData.logo}
                          style={{
                            width: "100px",
                            height: "100px",
                            widthborderRadius: "10%",
                            cursor: "pointer",
                          }}
                          alt="company"
                          onClick={() => this.ImageClickHandler(rowData)}
                        />
                      ),
                    },
                    {
                      title: "Website",
                      field: "website",
                      render: (rowData) =>
                        rowData.website !== null && rowData.website !== "" ? (
                          <a
                            href={rowData.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                    {
                      title: "Sector",
                      field: "sector",
                      render: (rowData) => this.getAllSectors(rowData),
                    },
                    {
                      title: "Founder(s)",
                      field: "founders",
                      render: (rowData) => this.getAllFounders(rowData),
                    },
                    {
                      title: "Class",
                      field: "class",
                    },
                    {
                      title: "Status",
                      field: "status",
                    },
                    {
                      title: "Women Led",
                      field: "womenLed",
                      render: (rowData) =>
                        rowData.womenLed !== null && rowData.womenLed !== ""
                          ? JSON.stringify(rowData.womenLed)
                          : "--",
                    },
                    {
                      title: "Longer Description",
                      field: "longerDescription",
                      render: (rowData) =>
                        rowData.longerDescription !== null &&
                        rowData.longerDescription !== ""
                          ? rowData.longerDescription
                          : "--",
                    },
                    {
                      title: "Short Description",
                      field: "shortDescription",
                      render: (rowData) =>
                        rowData.shortDescription !== null &&
                        rowData.shortDescription !== ""
                          ? rowData.shortDescription
                          : "--",
                    },
                    {
                      title: "LinkedIn",
                      field: "linkedln",
                      render: (rowData) =>
                        rowData.linkedln !== null && rowData.linkedln !== "" ? (
                          <a
                            href={rowData.linkedln}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                    {
                      title: "Twitter",
                      field: "twitter",
                      render: (rowData) =>
                        rowData.twitter !== null && rowData.twitter !== "" ? (
                          <a
                            href={rowData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                    {
                      title: "Instagram",
                      field: "instagram",
                      render: (rowData) =>
                        rowData.instagram !== null &&
                        rowData.instagram !== "" ? (
                          <a
                            href={rowData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                    {
                      title: "Calendly",
                      field: "calendlyLink",
                      render: (rowData) =>
                        rowData.calendlyLink !== null &&
                        rowData.calendlyLink !== "" ? (
                          <a
                            href={rowData.calendlyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                    {
                      title: "Summary Slide",
                      field: "summarySlide",
                      render: (rowData) =>
                        rowData.summarySlide !== null &&
                        rowData.summarySlide !== "" ? (
                          <a
                            href={rowData.summarySlide}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                    {
                      title: "Investment Memo",
                      field: "investmentMemo",
                      render: (rowData) =>
                        rowData.investmentMemo !== null &&
                        rowData.investmentMemo !== "" ? (
                          <a
                            href={rowData.investmentMemo}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                    {
                      title: "Individual Pitch",
                      field: "individualPitch",
                      render: (rowData) =>
                        rowData.individualPitch !== null &&
                        rowData.individualPitch !== ""
                          ? rowData.individualPitch
                          : "--",
                    },
                    {
                      title: "Job Board",
                      field: "jobBoard",
                      render: (rowData) =>
                        rowData.jobBoard !== null && rowData.jobBoard !== ""
                          ? rowData.jobBoard
                          : "--",
                    },
                    {
                      title: "Common Email",
                      field: "commonEmail",
                      render: (rowData) =>
                        rowData.commonEmail !== null &&
                        rowData.commonEmail !== ""
                          ? rowData.commonEmail
                          : "--",
                    },
                    {
                      title: "Country Code",
                      field: "numberISD",
                      render: (rowData) =>
                        rowData.numberISD !== null && rowData.numberISD !== ""
                          ? rowData.numberISD
                          : "--",
                    },
                    {
                      title: "Number",
                      field: "whatsappNumber",
                      render: (rowData) =>
                        rowData.whatsappNumber !== null &&
                        rowData.whatsappNumber !== ""
                          ? rowData.whatsappNumber
                          : "--",
                    },
                    {
                      title: "last Fundraise Headline",
                      field: "lastFundRaiseHeadline",
                      render: (rowData) =>
                        rowData.lastFundRaiseHeadline !== null &&
                        rowData.lastFundRaiseHeadline !== ""
                          ? rowData.lastFundRaiseHeadline
                          : "--",
                    },
                    {
                      title: "last Fundraise Link",
                      field: "lastFundraiseLink",
                      render: (rowData) =>
                        rowData.lastFundraiseLink !== null &&
                        rowData.lastFundraiseLink !== "" ? (
                          <a
                            href={rowData.lastFundraiseLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link{" "}
                          </a>
                        ) : (
                          "--"
                        ),
                    },
                  ]}
                  data={data}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: true,
                    headerStyle: {
                      backgroundColor: "#ccc",
                      color: "#000",
                      fontWeight: 600,
                    },
                    rowStyle: {
                      backgroundColor: "#EEE",
                    },
                    exportButton: true,
                    pageSize: 200,
                    pageSizeOptions: [100, 200, 300],
                  }}
                  isLoading={loading}
                  actions={[
                    {
                      icon: Refresh,
                      tooltip: "Refresh List",
                      isFreeAction: true,
                      onClick: () => this.loadData(),
                    },
                    {
                      icon: "add",
                      tooltip: "Create Company",
                      isFreeAction: true,
                      onClick: () => this.addCompanyClickHandler(),
                    },
                    (rowData) => ({
                      icon: Edit,
                      tooltip: "Update Company Details",
                      disabled: rowData.isactive == "N" ? true : false,
                      onClick: (event, rowData) =>
                        this.handleEditCompanyDetails(event, rowData),
                    }),
                    (rowData) => ({
                      icon: Delete,
                      tooltip: "Delete Company",
                      disabled:
                        loggedInUserDetails.data.role.toUpperCase() === "ADMIN"
                          ? true
                          : rowData.isactive == "Y"
                          ? true
                          : false,
                      onClick: (event, rowData) =>
                        this.handleDeleteCompany(event, rowData),
                    }),
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

CompanyList = reduxForm({
  form: "CompanyListForm",
  enableReinitialize: true,
})(CompanyList);

function mapStateToProps(state) {
  return {
    loading: state.companyReducer.loading,
    companyList:
      state.companyReducer.companyList !== null &&
      state.companyReducer.companyList.data,
    loggedInUserDetails:
      state.loginReducer.loggedInUserDetails !== null &&
      state.loginReducer.loggedInUserDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        GetCompanyListAction,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CompanyList));
