
/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    InvestorActionTypes,
    POST_CREATE_INVESTOR_API,
    GET_INVESTOR_LIST_API,
    PUT_UPDATE_INVESTOR_API,
    DELETE_INVESTOR_API,
    POST_APPROVE_INVESTED_COMPANIES_LIST_BY_INVESTOR_API,
    GET_INVESTED_COMPANIES_LIST_API,
    GET_COMPANIES_LIKED_BY_INVESTOR_LIST_API
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateInvestorAction = (postData, type) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorActionTypes.post_createInvestor.REQUEST));
        fetch(`${POST_CREATE_INVESTOR_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorActionTypes.post_createInvestor.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorActionTypes.post_createInvestor.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorActionTypes.post_createInvestor.SUCCESS, response));
                    dispatch(reset('AddEditInvestorModalForm'));
                    showSuccessMessage('Investor Created Successfully');
                    dispatch(GetInvestorListAction(true, false))
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorActionTypes.post_createInvestor.FAILURE));
                console.log('error post_createInvestor ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(InvestorActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetInvestorListAction = (isApproved, isVerified) => {
    let queryParam;
    if(isVerified && isApproved) {
        queryParam = `?verified=${isVerified}&approved=${isApproved}`
    } else if(isVerified) {
        queryParam = `?verified=${isVerified}`
    } else if(isApproved) {
        queryParam = `?approved=${isApproved}`
    } else {
        queryParam = `?verified=false&approved=false`
    }
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorActionTypes.get_investorList.REQUEST));
        fetch(`${GET_INVESTOR_LIST_API}${queryParam}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorActionTypes.get_investorList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorActionTypes.get_investorList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorActionTypes.get_investorList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorActionTypes.get_investorList.FAILURE));
                console.log('error get_investorList ..', error);
            });
    };
};

export const PutInvestorUpdateAction = (postData, investorId, isApproved, isVerified) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorActionTypes.put_updateInvestorDetail.REQUEST));
        fetch(`${PUT_UPDATE_INVESTOR_API}/${investorId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorActionTypes.put_updateInvestorDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorActionTypes.put_updateInvestorDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorActionTypes.put_updateInvestorDetail.SUCCESS, response));
                    showSuccessMessage('Investor Details Updated Successfully');
                    dispatch(GetInvestorListAction(isApproved, isVerified))
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorActionTypes.put_updateInvestorDetail.FAILURE));
                console.log('error put_updateInvestorDetail ..', error);
            });
    };
};

export const DeleteInvestorAction = (investorId, isApproved, isVerified) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorActionTypes.delete_investor.REQUEST));
        fetch(`${DELETE_INVESTOR_API}/${investorId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorActionTypes.delete_investor.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorActionTypes.delete_investor.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorActionTypes.delete_investor.SUCCESS, response));
                    showSuccessMessage('Investor Deleted Successfully');
                    dispatch(GetInvestorListAction(isApproved, isVerified))
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorActionTypes.delete_investor.FAILURE));
                console.log('error delete_investor ..', error);
            });
    };
};

export const GetInvestedCompaniesListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorActionTypes.get_investedCompaniesList.REQUEST));
        fetch(`${GET_INVESTED_COMPANIES_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorActionTypes.get_investedCompaniesList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorActionTypes.get_investedCompaniesList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorActionTypes.get_investedCompaniesList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorActionTypes.get_investedCompaniesList.FAILURE));
                console.log('error get_investedCompaniesList ..', error);
            });
    };
};



export const PostApproveInvestedCompaniesByInvestorAction = (postData, queryParam) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.REQUEST));
        fetch(`${POST_APPROVE_INVESTED_COMPANIES_LIST_BY_INVESTOR_API}${queryParam}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.SUCCESS, response));
                    showSuccessMessage('Company Approved Successfully');
                    dispatch(GetInvestedCompaniesListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorActionTypes.post_approveInvestedCompaniesByInvestorList.FAILURE));
                console.log('error post_approveInvestedCompaniesByInvestorList ..', error);
            });
    };
};


export const GetCompaniesLikedByInvestorListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorActionTypes.get_companiesLikedByInvestorList.REQUEST));
        fetch(`${GET_COMPANIES_LIKED_BY_INVESTOR_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorActionTypes.get_companiesLikedByInvestorList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorActionTypes.get_companiesLikedByInvestorList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorActionTypes.get_companiesLikedByInvestorList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorActionTypes.get_companiesLikedByInvestorList.FAILURE));
                console.log('error get_companiesLikedByInvestorList ..', error);
            });
    };
};
