
/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    InvestorTypeActionTypes,
    POST_CREATE_INVESTOR_TYPE_API,
    GET_INVESTOR_TYPE_LIST_API,
    PUT_UPDATE_INVESTOR_TYPE_API,
    DELETE_INVESTOR_TYPE_API,
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateInvestorTypeAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorTypeActionTypes.post_createInvestorType.REQUEST));
        fetch(`${POST_CREATE_INVESTOR_TYPE_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorTypeActionTypes.post_createInvestorType.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorTypeActionTypes.post_createInvestorType.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorTypeActionTypes.post_createInvestorType.SUCCESS, response));
                    dispatch(reset('AddEditInvestorModalForm'));
                    showSuccessMessage('Investor Type Created Successfully');
                    dispatch(GetInvestorTypeListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorTypeActionTypes.post_createInvestorType.FAILURE));
                console.log('error post_createInvestorType ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(InvestorTypeActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetInvestorTypeListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorTypeActionTypes.get_investorTypeList.REQUEST));
        fetch(`${GET_INVESTOR_TYPE_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorTypeActionTypes.get_investorTypeList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorTypeActionTypes.get_investorTypeList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorTypeActionTypes.get_investorTypeList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorTypeActionTypes.get_investorTypeList.FAILURE));
                console.log('error get_investorTypeList ..', error);
            });
    };
};

export const PutInvestorTypeUpdateAction = (postData, investorTypeId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorTypeActionTypes.put_updateInvestorTypeDetail.REQUEST));
        fetch(`${PUT_UPDATE_INVESTOR_TYPE_API}/${investorTypeId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorTypeActionTypes.put_updateInvestorTypeDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorTypeActionTypes.put_updateInvestorTypeDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorTypeActionTypes.put_updateInvestorTypeDetail.SUCCESS, response));
                    showSuccessMessage('Investor Type Details Updated Successfully');
                    dispatch(GetInvestorTypeListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorTypeActionTypes.put_updateInvestorTypeDetail.FAILURE));
                console.log('error put_updateInvestorTypeDetail ..', error);
            });
    };
};

export const DeleteInvestorTypeAction = (investorTypeId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(InvestorTypeActionTypes.delete_investorType.REQUEST));
        fetch(`${DELETE_INVESTOR_TYPE_API}/${investorTypeId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(InvestorTypeActionTypes.delete_investorType.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(InvestorTypeActionTypes.delete_investorType.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(InvestorTypeActionTypes.delete_investorType.SUCCESS, response));
                    showSuccessMessage('Investor Type Deleted Successfully');
                    dispatch(GetInvestorTypeListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(InvestorTypeActionTypes.delete_investorType.FAILURE));
                console.log('error delete_investorType ..', error);
            });
    };
};

