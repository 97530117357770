
// @material-ui/icons
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import StoreIcon from '@material-ui/icons/Store';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import StyleIcon from '@material-ui/icons/Style';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CompareIcon from '@material-ui/icons/Compare';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CropIcon from '@material-ui/icons/Crop';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import DoneIcon from '@material-ui/icons/Done';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import Dashboard from '../../views/Modules/Dashboard/Dashboard';
import ManageUser from '../../views/Modules/UAM/ManageUser';
import FoundersList from '../../views/Modules/Founders/FoundersList';
import InvestorsList from '../../views/Modules/Investor/InvestorsList';
import InvestorTypeList from '../../views/Modules/InvestorType/InvestorTypeList';
import SectorList from '../../views/Modules/Sector/SectorList';
import StatusList from '../../views/Modules/Status/StatusList';
import ClassList from '../../views/Modules/Class/ClassList';
import DomainsList from '../../views/Modules/Domains/DomainsList';
import ApproveInvestorList from '../../views/Modules/Investor/Approve/InvestorApproveList';
import ApproveInvestorCompaniesList from '../../views/Modules/Investor/AllInvestedCompaniesList';
import InvestorLikedCompaniesList from '../../views/Modules/Investor/CompaniesLikedByInvestorList';
import CompaniesList from '../../views/Modules/Company/CompanyList';
import GenerateMagicToken from '../../views/Modules/PitchDay/GenerateMagicToken';
import AllPitchEvents from '../../views/Modules/PitchDay/PitchEvent';
import AllCompaniesByEvent from '../../views/Modules/PitchDay/CompaniesByEvent';
import AllInvestorByEvent from '../../views/Modules/PitchDay/InvestorByEvent';

var adminRoutes = [
    {
        collapse: true,
        name: "Dashboard",
        icon: DashboardIcon,
        state: "dashboardCollapse",
        views: [
            {
                path: "/dashboard",
                name: "Dashboard",
                mini: "DSB",
                component: Dashboard,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Founders",
        icon: PeopleAltIcon,
        state: "foundersCollapse",
        views: [
            {
                path: "/founderlist",
                name: "Founder List",
                mini: "FL",
                component: FoundersList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Companies",
        icon: PeopleAltIcon,
        state: "companyCollapse",
        views: [
            {
                path: "/companylist",
                name: "Company List",
                mini: "CL",
                component: CompaniesList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Pitch Day",
        icon: PeopleAltIcon,
        state: "pdCollapse",
        views: [
            {
                path: "/allPitchEvents",
                name: "All Pitch Events",
                mini: "APE",
                component: AllPitchEvents,
                layout: "/admin"
            },
            {
                path: "/generateMagicToken",
                name: "Generate Magic Token",
                mini: "GMT",
                component: GenerateMagicToken,
                layout: "/admin"
            },
            {
                path: "/CompaniesByEvent",
                name: "Companies By Event",
                mini: "CBE",
                component: AllCompaniesByEvent,
                layout: "/admin"
            },
            {
                path: "/investorByEvent",
                name: "Investors By Event",
                mini: "IBE",
                component: AllInvestorByEvent,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Investors",
        icon: PeopleAltIcon,
        state: "investorsCollapse",
        views: [
            {
                path: "/investorslist",
                name: "Investor List",
                mini: "IL",
                component: InvestorsList,
                layout: "/admin"
            },
            {
                path: "/investorsApprovelist",
                name: "Approve Investor List",
                mini: "AIL",
                component: ApproveInvestorList,
                layout: "/admin"
            },
            {
                path: "/investorsCompaniesApprovelist",
                name: "Approve Investor Companies",
                mini: "AIC",
                component: ApproveInvestorCompaniesList,
                layout: "/admin"
            },
            {
                path: "/companiesLikedByInvestor",
                name: "Investor Liked Companies",
                mini: "ALC",
                component: InvestorLikedCompaniesList,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Master",
        icon: AccountBalanceIcon,
        state: "masterCollapse",
        views: [
            {
                path: "/investorTypeList",
                name: "Investor Type",
                mini: "IT",
                component: InvestorTypeList,
                layout: "/admin"
            },
            {
                path: "/sectorList",
                name: "Sector",
                mini: "SEC",
                component: SectorList,
                layout: "/admin"
            },
            {
                path: "/statusList",
                name: "Status",
                mini: "STS",
                component: StatusList,
                layout: "/admin"
            },
            {
                path: "/classList",
                name: "Class",
                mini: "CLS",
                component: ClassList,
                layout: "/admin"
            },
            {
                path: "/domainsList",
                name: "Domains",
                mini: "DMS",
                component: DomainsList,
                layout: "/admin"
            },
        ]
    },
    // {
    //     collapse: true,
    //     name: "Payslip",
    //     icon: ReceiptIcon,
    //     state: "payslipCollapse",
    //     views: [
    //         {
    //             path: "/payslipupload",
    //             name: "Payslip Upload",
    //             mini: "PSU",
    //             component: PayslipUpload,
    //             layout: "/admin"
    //         },
    //         {
    //             path: "/paysliplist",
    //             name: "Payslip List",
    //             mini: "PL",
    //             component: PayslipList,
    //             layout: "/admin"
    //         },
    //     ]
    // },
    // {
    //     collapse: true,
    //     name: "Attendance",
    //     icon: DateRangeIcon,
    //     state: "attendanceCollapse",
    //     views: [
    //         {
    //             path: "/attendanceupload",
    //             name: "Attendance Upload",
    //             mini: "PSU",
    //             component: AttendanceUpload,
    //             layout: "/admin"
    //         },
    //         {
    //             path: "/attendancelist",
    //             name: "Attendance List",
    //             mini: "PL",
    //             component: AttendanceList,
    //             layout: "/admin"
    //         },
    //     ]
    // },
    // {
    //     collapse: true,
    //     name: "Appraisal",
    //     icon: LocalPrintshopIcon,
    //     state: "appraisalCollapse",
    //     views: [
    //         {
    //             path: "/appraisalUpload",
    //             name: "Upload Appraisal",
    //             mini: "UA",
    //             component: BulkAppraisalUpload,
    //             layout: "/admin"
    //         },
    //         {
    //             path: "/appraisalList",
    //             name: "Appraisal List",
    //             mini: "AL",
    //             component: AppraisalList,
    //             layout: "/admin"
    //         },
    //     ]
    // },
    // {
    //     collapse: true,
    //     name: "Banner",
    //     icon: BrandingWatermarkIcon,
    //     state: "bannerCollapse",
    //     views: [
    //         {
    //             path: "/bannerupload",
    //             name: "Upload Banner",
    //             mini: "UOB",
    //             component: BannerUpload,
    //             layout: "/admin"
    //         },
    //         {
    //             path: "/bannerList",
    //             name: "Banner List",
    //             mini: "UL",
    //             component: BannerList,
    //             layout: "/admin"
    //         },
    //     ]
    // },
    // {
    //     collapse: true,
    //     name: "EPF",
    //     icon: AccountBalanceWalletIcon,
    //     state: "epfCollapse",
    //     views: [
    //         {
    //             path: "/epflist",
    //             name: "EPF List",
    //             mini: "EL",
    //             component: EpfList,
    //             layout: "/admin"
    //         },
    //     ]
    // },
    // {
    //     collapse: true,
    //     name: "Support",
    //     icon: ContactMailIcon,
    //     state: "supportCollapse",
    //     views: [
    //         {
    //             path: "/support",
    //             name: "Support List",
    //             mini: "SPL",
    //             component: SupportList,
    //             layout: "/admin"
    //         }
    //     ]
    // },
    {
        collapse: true,
        name: "Manage Roles",
        icon: PeopleAltIcon,
        state: "uramCollapse",
        views: [
            {
                path: "/roleaccess",
                name: "User Role Access",
                mini: "URA",
                component: ManageUser,
                layout: "/admin"
            },
        ]
    },
    
];
export default adminRoutes;
