
/**
 @desc
 * Centralized unique actions for banner Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    ClassActionTypes,
    POST_CREATE_CLASS_API,
    GET_CLASS_LIST_API,
    PUT_UPDATE_CLASS_API,
    DELETE_CLASS_API,
} from '../constants/index';
import { showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../../shared/utility';
import { reset } from "redux-form";


export const PostCreateClassAction = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(ClassActionTypes.post_createClass.REQUEST));
        fetch(`${POST_CREATE_CLASS_API}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(ClassActionTypes.post_createClass.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(ClassActionTypes.post_createClass.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(ClassActionTypes.post_createClass.SUCCESS, response));
                    dispatch(reset('AddEditClassModalForm'));
                    showSuccessMessage('Class Created Successfully');
                    dispatch(GetClassListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(ClassActionTypes.post_createClass.FAILURE));
                console.log('error post_createClass ..', error);
            });
    };
};

export const ResetErrorDataAction = () => {
    return dispatch => {
        dispatch(actionCreator(ClassActionTypes.reset_errorData.SUCCESS, null));
    }
}

export const GetClassListAction = () => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(ClassActionTypes.get_classList.REQUEST));
        fetch(`${GET_CLASS_LIST_API}`, {
            method: 'GET',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(ClassActionTypes.get_classList.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(ClassActionTypes.get_classList.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(ClassActionTypes.get_classList.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(ClassActionTypes.get_classList.FAILURE));
                console.log('error get_classList ..', error);
            });
    };
};

export const PutClassUpdateAction = (postData, classId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(ClassActionTypes.put_updateClassDetail.REQUEST));
        fetch(`${PUT_UPDATE_CLASS_API}/${classId}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(ClassActionTypes.put_updateClassDetail.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(ClassActionTypes.put_updateClassDetail.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(ClassActionTypes.put_updateClassDetail.SUCCESS, response));
                    showSuccessMessage('Class Details Updated Successfully');
                    dispatch(GetClassListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(ClassActionTypes.put_updateClassDetail.FAILURE));
                console.log('error put_updateClassDetail ..', error);
            });
    };
};

export const DeleteClassAction = (classId) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(ClassActionTypes.delete_class.REQUEST));
        fetch(`${DELETE_CLASS_API}/${classId}`, {
            method: 'DELETE',
            headers: jsonApiHeader(token),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(ClassActionTypes.delete_class.FAILURE, response));
                    showErrorMessage(response.message)
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000);
                    localStorage.clear();
                    dispatch(actionCreator(ClassActionTypes.delete_class.FAILURE, response));
                    window.location.reload();
                } else {
                    dispatch(actionCreator(ClassActionTypes.delete_class.SUCCESS, response));
                    showSuccessMessage('Class Deleted Successfully');
                    dispatch(GetClassListAction())
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(ClassActionTypes.delete_class.FAILURE));
                console.log('error delete_class ..', error);
            });
    };
};

