import { DomainsActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    createDomains: null,
    domainsList: null,
    UpdateDomains: null,
    deleteDomains: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case DomainsActionTypes.post_createDomains.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DomainsActionTypes.post_createDomains.SUCCESS:
            return {
                ...state,
                loading: false,
                createDomains: payload,
            };
        case DomainsActionTypes.post_createDomains.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case DomainsActionTypes.get_domainsList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DomainsActionTypes.get_domainsList.SUCCESS:
            return {
                ...state,
                loading: false,
                domainsList: payload,
            };
        case DomainsActionTypes.get_domainsList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case DomainsActionTypes.delete_domains.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DomainsActionTypes.delete_domains.SUCCESS:
            return {
                ...state,
                loading: false,
                deleteDomains: payload,
            };
        case DomainsActionTypes.delete_domains.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case DomainsActionTypes.put_updateDomainsDetail.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DomainsActionTypes.put_updateDomainsDetail.SUCCESS:
            return {
                ...state,
                loading: false,
                UpdateDomains: payload,
            };
        case DomainsActionTypes.put_updateDomainsDetail.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case DomainsActionTypes.reset_errorData.SUCCESS:
            return {
                ...state,
                errorData: payload
            }
        default:
            return state;
    }
};
