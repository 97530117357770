
/**
 @desc
 * Centralized unique actions for Login Module.
 */

import {
    actionCreator,
    checkHttpStatus,
    jsonApiHeader,
    showErrorMessage,
    LoginActionTypes,
    POST_LOGIN_API,
    GET_REFRESH_TOKEN_API,
    POST_CHANGE_PASSWORD_API
} from '../constants/index';
import { handleLoginRedirect, showSuccessMessage, TOKEN_EXPIRY_MESSAGE } from '../../../shared/utility';


/**
 * @param  {[object]} postData [The data needed as a payload for the API interaction]
 */
export const LoginUserAction = (postData) => {
    return dispatch => {
        dispatch(actionCreator(LoginActionTypes.post_Login.REQUEST));
        fetch(`${POST_LOGIN_API}`, { 
            method: 'POST',
            body: JSON.stringify(postData),
            headers: jsonApiHeader(),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(LoginActionTypes.post_Login.FAILURE, response));
                    showErrorMessage(response.message)
                } else {
                    handleLoginRedirect(response, '/admin/dashboard');
                    setTimeout(() => {
                        dispatch(actionCreator(LoginActionTypes.post_Login.SUCCESS, response));
                    }, 100);
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(LoginActionTypes.post_Login.FAILURE));
                console.log('error post_Login ..', error);
            });
    };
};


/**
 * @note [token is going in the header] 
 * @param  {} requestToken [Token needed as a payload for the API interaction]
 */
export const RefreshTokenAction = (requestToken) => {
    return dispatch => {
        dispatch(actionCreator(LoginActionTypes.get_RefreshTokenApi.REQUEST));
        fetch(`${GET_REFRESH_TOKEN_API}`, {
            method: 'POST',
            headers: jsonApiHeader(requestToken),
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if (response.status.toUpperCase() === "FAILURE") {
                    dispatch(actionCreator(LoginActionTypes.get_RefreshTokenApi.FAILURE, response));
                } else if (response.status.toUpperCase() === "EXPIRED") {
                    if(localStorage.getItem('LoggedOut') && localStorage.getItem('LoggedOut') === "true"){
                        showSuccessMessage('Logged out successfully');
                        localStorage.clear();
                        dispatch(actionCreator(LoginActionTypes.get_RefreshTokenApi.FAILURE, response));
                    } else {
                        showErrorMessage(TOKEN_EXPIRY_MESSAGE, 3000)
                        localStorage.clear();
                        dispatch(actionCreator(LoginActionTypes.get_RefreshTokenApi.FAILURE, response));
                    }
                } else {
                    dispatch(actionCreator(LoginActionTypes.get_RefreshTokenApi.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(LoginActionTypes.get_RefreshTokenApi.FAILURE));
                console.log('error get_RefreshTokenApi ..', error);
            });
    };
};

export const PostChangeCurrentUserPassword = (postData) => {
    return (dispatch, getState) => {
        let token = getState().loginReducer.loggedInUserDetails.data.token;
        dispatch(actionCreator(LoginActionTypes.post_ChangePasswordApi.REQUEST));
        fetch(`${POST_CHANGE_PASSWORD_API}`, {
            method: 'PUT',
            headers: jsonApiHeader(token),
            body: JSON.stringify(postData)
        })
            .then(checkHttpStatus)
            .then(function (response) {
                if(response.status.toUpperCase() === "FAILED") {
                    dispatch(actionCreator(LoginActionTypes.post_ChangePasswordApi.FAILURE, response));
                    showErrorMessage(response.message)
                } else if(response.status.toUpperCase() === "EXPIRED") {
                    localStorage.clear();
                    showErrorMessage(TOKEN_EXPIRY_MESSAGE)
                    dispatch(actionCreator(LoginActionTypes.post_ChangePasswordApi.FAILURE, response));
                    window.location.reload(); 
                } else {
                    showSuccessMessage(response.message);
                    dispatch(actionCreator(LoginActionTypes.post_ChangePasswordApi.SUCCESS, response));
                }
            })
            .catch(function (error) {
                dispatch(actionCreator(LoginActionTypes.post_ChangePasswordApi.FAILURE));
                console.log('error post_ChangePasswordApi ..', error);
            });
    };
};

