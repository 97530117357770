/**
 @desc
 * Centralized unique action types for Onboarding module.
 */


import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
    showSuccessMessage,
    showErrorMessage,
} from '../../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus,
    showSuccessMessage,
    showErrorMessage,
};

export const POST_CREATE_INVESTOR_API = `${API_URL}/investors/add`;
export const GET_INVESTOR_LIST_API = `${API_URL}/investors/get`;
export const PUT_UPDATE_INVESTOR_API = `${API_URL}/investors/update`;
export const DELETE_INVESTOR_API = `${API_URL}/investors/delete`;
export const GET_INVESTED_COMPANIES_LIST_API = `${API_URL}/admins/InvestedCompanies`;
export const POST_APPROVE_INVESTED_COMPANIES_LIST_BY_INVESTOR_API = `${API_URL}/admins/approveCompany`;
export const GET_COMPANIES_LIKED_BY_INVESTOR_LIST_API = `${API_URL}/admins/getInvestorLikedCompanies`;

export const InvestorActionTypes = {
    post_createInvestor: createRequestActionTypes('POST_CREATE_INVESTOR'),
    get_investorList: createRequestActionTypes('GET_INVESTOR_LIST'),
    put_updateInvestorDetail: createRequestActionTypes('UPDATE_INVESTOR_DETAIL'),
    delete_investor: createRequestActionTypes('DELETE_INVESTOR'),
    get_investedCompaniesList: createRequestActionTypes('GET_INVESTED_COMPANIES_LIST'),
    get_companiesLikedByInvestorList: createRequestActionTypes('GET_COMPANIES_LIKED_BY_INVESTOR_LIST_API'),
    post_approveInvestedCompaniesByInvestorList: createRequestActionTypes('POST_APPROVE_INVESTED_COMPANIES_BY_INVESTOR_LIST_API'),
    reset_errorData: createRequestActionTypes('RESET_ERROR_DATA'),
};