import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'; // SAYING use redux form reducers as reducers

import loginReducer from '../views/Login/reducers/LoginReducers';
import UamReducer from '../views/Modules/UAM/reducers/UamReducers';
import founderReducer from '../views/Modules/Founders/reducers/FoundersReducers';
import investorReducer from '../views/Modules/Investor/reducers/InvestorsReducers';
import investorTypeReducer from '../views/Modules/InvestorType/reducers/InvestorTypeReducers';
import sectorReducer from '../views/Modules/Sector/reducers/SectorReducers';
import statusReducer from '../views/Modules/Status/reducers/StatusReducers';
import classReducer from '../views/Modules/Class/reducers/ClassReducers';
import domainsReducer from '../views/Modules/Domains/reducers/DomainsReducers';
import companyReducer from '../views/Modules/Company/reducers/CompanyReducers';
import pitchDayReducer from '../views/Modules/PitchDay/reducers/PitchDayReducers';

export default combineReducers({
    form: formReducer,
    loginReducer: loginReducer,
    founderReducer: founderReducer,
    investorReducer: investorReducer,
    investorTypeReducer: investorTypeReducer,
    sectorReducer: sectorReducer,
    statusReducer: statusReducer,
    classReducer: classReducer,
    domainsReducer: domainsReducer,
    companyReducer: companyReducer,
    pitchDayReducer: pitchDayReducer,
    uamReducer: UamReducer,
})