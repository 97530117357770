import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      width: 520
    }
  }));

function RenderMultiSelect({ label, sectorData, selected, handleChange }) {

    const classes = useStyles();

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <InputLabel id="mutiple-select-label">{label}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={(e) => handleChange(e)}
        renderValue={(selected) => selected.join(", ")}
      >
        {sectorData.map((option, index) => (
          <MenuItem key={index} value={option.name}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option.name) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

}

export default RenderMultiSelect;
